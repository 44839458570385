import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useSigninCheck } from 'reactfire';
import DashboardContainer from '../containers/DashboardContainer';

const PrivateRoute = ({ props, children, ...rest }) => {
	const { data: signInCheckResult } = useSigninCheck();
	const auth = getAuth();
	
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				// Calculate the remaining time until the session expires (in milliseconds)
				const expiresInMs = 3600000 - (Date.now() - new Date(user.metadata.lastSignInTime).getTime());
				const expiresInMin = Math.floor(expiresInMs / 60000);
				// const expiresInMs = Math.floor(new Date(user.metadata.lastSignInTime).getTime() + 3600000 - Date.now() / 60000);

				//// console.log(`User session will expire in ${expiresInMin} minutes`);

				// Check if the session has expired
				const sessionExpired = expiresInMin <= 0;
				if (sessionExpired) {
					signOut(auth);
				}
				// setHasSessionExpired(sessionExpired);
			} else {
				signOut(auth);
				// setHasSessionExpired(true);
			}
		});

		return unsubscribe;
	}, [children]);
	
	return (
		<Route
			{...rest}
			render={(props) =>
				signInCheckResult.signedIn ? (
					// !hasSessionExpired ? (
					<DashboardContainer>{children}</DashboardContainer>
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};


export default PrivateRoute;
