import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import Stack from '@mui/material/Stack';

const ScheduleDateTime = ({ handleScheduleDateTimeChange }) => {
    const [value, setValue] = useState(new Date());

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
                <StaticDateTimePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={value}
                    minutesStep={30}
                    onChange={(newValue) => {
                        setValue(newValue);
                        handleScheduleDateTimeChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                {/* <DateTimePicker
                    label="Responsive"
                    renderInput={(params) => <TextField {...params} />}
                    value={value}
                    minutesStep="30"
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                /> */}
            </Stack>
        </LocalizationProvider>
    );
}
export default ScheduleDateTime;