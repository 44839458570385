import React, { useState } from 'react';
import {
    Avatar,
    Button,
    TextField,
    Link,
    Paper,
    Box,
    Grid,
    Typography,
    Stack,
    Container,
    Snackbar,
    Alert,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "tss-react/mui";
import { LoadingButton } from '@mui/lab';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signOut, sendSignInLinkToEmail } from 'firebase/auth';
import firebase, { initializeApp } from 'firebase/app';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, documentId, setDoc, collection, query, where, limit } from 'firebase/firestore';
import { connect } from 'react-redux';

import { CircularProgress } from '@material-ui/core';

import backgroundImage from '../Public/assets/img/mujaz/mujaz-bg-01.jpg';

import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

const errorMessages = {
    'missing-fields': 'الرجاء إدخال جميع المعلومات المطلوبة',
    'email-taken': 'البريد الإلكتروني مسجل مسبقاً',
    'email-wrong-format': 'البريد الإلكتروني خاطئ',
    'company-taken': 'الشركة/المؤسسة مسجلة مسبقاً',
};

const SignupLoadingStatus = ({ isActive }) => {
    const useStyles = makeStyles()(
        () => ({
            signupOverlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                zIndex: 9999
            },
            signupSpinnerContainer: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            },
            signupText: {
                margin: '10px 0',
                color: 'white',
                fontSize: '16px'
            }

        })
    );

    const { classes } = useStyles();
    if (!isActive) {
        return null;
    }

    return (
        <div className={classes.signupOverlay}>
            <div className={classes.signupSpinnerContainer}>
                <p className={classes.signupText}>الرجاء الإنتظار، يتم إنشاء حسابك...</p>
                <CircularProgress style={{ color: '#ff7764' }} />
            </div>
        </div>
    );
};


const Signup = (props) => {
    const history = useHistory();
    const [newAccount, setNewAccount] = useState({
        company: '',
        name: '',
        email: '',
        password: ''
    });
    const [awsSES, setAwsSES] = useState('');
    const [awsCW, setAwsCW] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const [showPassword, setShowPassword] = React.useState(false);

    const auth = getAuth();
    const db = getFirestore();

    let accountData =
    {
        personalProfile: {
            personalData:
            {
                username: newAccount.email,
                name: newAccount.name,
                email: newAccount.email
            },
            companyData:
            {
                company: newAccount.company,
                website: '',
                address: '',
                city: '',
                country: '',
            }
        },
        settings:
        {
            senderSettings:
            {
                name: '',
                email: '',
                toPreview: '',
                defaultFrom: '',
                defaultReplyTo: '',
                aws: {
                    ses: '',
                    cw: ''
                }
            },
            view:
            {
                logo: '',
                primaryColor: '',
                secondaryColor: '',
            },
            footer:
            {
                footerLogo: '',
                text: '',
                slogon: '',
                copyrightText: '',
                unsubscribeText: '',
                socialMedia:
                {
                    facebook: '',
                    instagram: '',
                    twitter: '',
                    youtube: ''
                }
            },
            sponsorShip: {
                enabled: false,
                type: "manual"
            }
        }

    }



    const addCompanySettings = async (accessSES, accessCW) => {
        await addDoc(collection(db, "Companies"), {
            personalProfile: {
                personalData:
                {
                    username: newAccount.email,
                    name: newAccount.name,
                    email: newAccount.email
                },
                companyData:
                {
                    company: newAccount.company,
                    website: '',
                    address: '',
                    city: '',
                    country: '',
                }
            },
            settings:
            {
                senderSettings:
                {
                    name: '',
                    email: '',
                    toPreview: '',
                    defaultFrom: '',
                    defaultReplyTo: '',
                    aws: {
                        ses: accessSES,
                        cw: accessCW
                    }
                },
                view:
                {
                    logo: '',
                    primaryColor: '',
                    secondaryColor: '',
                },
                sponsorShip: {
                    enabled: false,
                    type: 'auto'
                },
                footer:
                {
                    footerLogo: '',
                    text: '',
                    slogon: '',
                    copyrightText: '',
                    unsubscribeText: '',
                    socialMedia:
                    {
                        facebook: '',
                        instagram: '',
                        twitter: '',
                        youtube: ''
                    }
                },
                package: {
                    active: true,
                    sentEmails: 0,
                    totalEmails: 1000,
                    type: 'free'
                }
            }

        })
            .then(() => {
                console.log('settings added');
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const addNewUser = async (uid) => {
        await addDoc(collection(db, "Users"), {
            company: newAccount.company,
            email: newAccount.email,
            logo: '',
            _2fa: {
                enabled: false,
                secret: ''
            },
            name: newAccount.name,
            responsibility: 'manager',
            uid: uid
        })
            .then((data) => {
                console.log('settings added');
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const isAccountComplete = (account) => {
        return Object.values(account).every(value => value !== '');
    }
    const onSubmit = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (newAccount.company == '' || newAccount.name == '' || newAccount.email == '' || newAccount.password == '') {
            setSnackBar({
                isOpen: true,
                message: 'من فضلك إملأ جميع الحقول',
            });
            return;
        }
        // Check if newAccount.email is not a valid email
        if (!emailRegex.test(newAccount.email)) {
            setSnackBar({
                isOpen: true,
                message: 'صيغة عنوان البريد الإلكتروني غير صالحة',
            });
            return;
        }
      
        try {
            setIsLoading(true);
            setIsActive(true);
            await fetch('https://us-central1-mujaz-platform.cloudfunctions.net/registerNewCompany', {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': 'https://mujaz.io',
                    'Access-Control-Allow-Methods': 'post',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                },
                body: JSON.stringify({
                    company: newAccount.company,
                    name: newAccount.name,
                    email: newAccount.email,
                    password: newAccount.password
                })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then(data => {
                    // Reset form after successful submission
                    setNewAccount({
                        company: '',
                        name: '',
                        email: '',
                        password: ''
                    });
                    setIsLoading(false);
                    setIsActive(false);
                    history.push('/login?companyCreated=true');
                })
                .catch(error => {
                    console.log('error: ' + error);
                })
        } catch (error) {
            setIsLoading(false);
            setIsActive(false);
            console.error('Error adding new account:', error);
        }
    };




    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <SignupLoadingStatus
                isActive={isActive}
            />
            <section className="wrapper image-wrapper bg-overlay bg-overlay-light-300 text-white"
                style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: '0px -130px' }}
            >
                <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
                    <div className="row">
                        {/* <div className="col-lg-8 mx-auto">
                            <h1 className="display-1 mb-3">تسجيل حساب جديد</h1>
                            <nav className="d-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item white-text"><a href="#">الرئيسية</a></li>
                                    <li className="breadcrumb-item active white-text" aria-current="page">تسجيل حساب جديد</li>
                                </ol>
                            </nav>

                        </div> */}

                    </div>

                </div>
            </section>

            <section className="wrapper bg-light">
                <div className="container pb-14 pb-md-16" style={{ backgroundPosition: '0px 130px' }}>
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 col-xxl-6 mx-auto mt-n20">
                            <div className="card">
                                <div className="card-body p-11 text-center" >

                                    <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 1 }}>
                                        <Grid item xs={1} sm={1} md={1} mb={6}>
                                            <Typography variant='h6' className="text-end" sx={{ fontWeight: "bold", color: "#072448" }}>ابدأ الان, واذا كنت تريد مجانا !</Typography>
                                            <Typography variant='body1' className="text-end" sx={{ fontSize: "15px" }}>قم بالتسجيل خلال ثواني لا يلزم وجود بطاقة ائتمان</Typography>
                                        </Grid>
                                        {Boolean(errorMessage) && (
                                            <Alert variant="standard" color="error" style={{ width: '100%' }}>
                                                <Typography>{errorMessage}</Typography>
                                            </Alert>
                                        )}
                                        {Boolean(successMessage) && (
                                            <Alert variant="standard" color="success" style={{ width: '100%' }}>
                                                <Typography>{successMessage}</Typography>
                                            </Alert>
                                        )}
                                        <Grid item xs={1} sm={1} md={1}>
                                            <Typography variant="body2" className="text-end" sx={{ fontSize: "18px" }}>اسم الشركة او المؤسسة</Typography>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="company"
                                                // label="الشركة/المؤسسة"
                                                placeholder="اسم الشركة او المؤسسة"
                                                autoComplete="company"
                                                value={newAccount.company}
                                                onChange={({ target: { value } }) => setNewAccount({
                                                    ...newAccount,
                                                    company: value
                                                })}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        fontSize: '0.7rem', // Smaller font size
                                                    },
                                                    '& .muirtl-1jnhzms-MuiInputBase-root-MuiOutlinedInput-root': {
                                                        height: '50px',
                                                        borderRadius: '5px',
                                                    },
                                                    marginTop: '1px',
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={1} sm={1} md={1}>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="name"
                                                label="الإسم الكامل"
                                                autoComplete="name"
                                                value={newAccount.name}
                                                onChange={({ target: { value } }) => setNewAccount({
                                                    ...newAccount,
                                                    name: value
                                                })}
                                            />
                                        </Grid> */}
                                        <Grid item container spacing={1} >
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Typography variant="body2" className="text-end" sx={{ fontSize: "18px" }}>الإسم الكامل</Typography>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="name"
                                                    placeholder="الإسم الكامل"
                                                    autoComplete="name"
                                                    value={newAccount.name}
                                                    onChange={({ target: { value } }) => setNewAccount({
                                                        ...newAccount,
                                                        name: value
                                                    })}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            fontSize: '0.7rem', // Smaller font size
                                                        },
                                                        '& .muirtl-1jnhzms-MuiInputBase-root-MuiOutlinedInput-root': {
                                                            height: '50px',
                                                            borderRadius: '5px',
                                                        },
                                                        marginTop: '1px'
                                                    }}
                                                />
                                            </Grid>
                                            {/* <Grid item lg={6}>
                                                <Typography variant="body2" className="text-end" sx={{ fontSize: "18px" }}>الإسم الأخير</Typography>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="name"
                                                    placeholder="الإسم الأخير"
                                                    autoComplete="name"
                                                    value={newAccount.name}
                                                    onChange={({ target: { value } }) => setNewAccount({
                                                        ...newAccount,
                                                        name: value
                                                    })}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            fontSize: '0.7rem', // Smaller font size
                                                        },
                                                        '& .muirtl-1jnhzms-MuiInputBase-root-MuiOutlinedInput-root': {
                                                            height: '50px',
                                                            borderRadius: '5px',
                                                        },
                                                        marginTop: '1px'
                                                    }}
                                                />
                                            </Grid> */}
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <Typography variant="body2" className="text-end" sx={{ fontSize: "18px" }}>البريد الالكتروني</Typography>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="email"
                                                // label="البريد الالكتروني"
                                                placeholder='البريد الالكتروني'
                                                autoComplete="email"
                                                value={newAccount.email}
                                                onChange={({ target: { value } }) => setNewAccount({
                                                    ...newAccount,
                                                    email: value
                                                })}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        fontSize: '0.7rem', // Smaller font size
                                                    },
                                                    '& .muirtl-1jnhzms-MuiInputBase-root-MuiOutlinedInput-root': {
                                                        height: '50px',
                                                        borderRadius: '5px',
                                                    },
                                                    marginTop: '1px'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <Typography variant="body2" className="text-end" sx={{ fontSize: "18px" }}>كلمة المرور</Typography>
                                            {/* <TextField
                                                margin="normal"
                                                fullWidth
                                                placeholder='كلمة السر'
                                                type="password"
                                                id="password"
                                                autoComplete="current-password"
                                                value={newAccount.password}
                                                onChange={({ target: { value } }) => setNewAccount({
                                                    ...newAccount,
                                                    password: value
                                                })}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        fontSize: '0.7rem', // Smaller font size
                                                    },
                                                    '& .muirtl-1jnhzms-MuiInputBase-root-MuiOutlinedInput-root': {
                                                        height: '50px',
                                                        borderRadius: '5px',
                                                    },
                                                    marginTop: '1px'
                                                }}
                                            /> */}

                                            <FormControl sx={{ width: '100%' }} variant="outlined" className='rounded'>
                                                {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                                <OutlinedInput
                                                    id="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder='كلمة المرور'
                                                    endAdornment={
                                                        <InputAdornment position="start">
                                                            <IconButton
                                                                aria-label={
                                                                    showPassword ? 'hide the password' : 'display the password'
                                                                }
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                onMouseUp={handleMouseUpPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    onChange={({ target: { value } }) => setNewAccount({
                                                        ...newAccount,
                                                        password: value
                                                    })}
                                                    sx={{
                                                        '& .muirtl-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                                                            fontSize: '.7rem'
                                                        },
                                                        height: '50px',
                                                        borderRadius: '5px',
                                                    }}
                                                />
                                            </FormControl>


                                        </Grid>
                                        <Grid item mt={2} mb={2}>
                                            <Typography variant="body2" className="text-end" sx={{ fontSize: "18px" }}> بالنقر فوق هذا الرابط فانك توافق على شروط الاستخدام وسياسه الخصوصيه وسياسه مكافحه البريد العشوائي</Typography>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <LoadingButton
                                                className="btn btn-primary rounded btn-login w-100 mb-2"
                                                variant="contained"
                                                onClick={() => onSubmit()}
                                                loading={isLoading}
                                                disabled={isLoading}
                                            >تسجيل حساب جديد</LoadingButton>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs>
                                            <Link href="/login" variant="body2" style={{ textDecoration: 'none' }} className="hover">
                                                تسجيل الدخول
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    {/* <p className="mb-0">ليس لديك حساب؟ <a href="./signup.html" className="hover">تسجيل حساب جديد</a></p> */}
                                </div>
                            </div>
                        </div>

                        {/* <div className="d-flex justify-content-center align-content-center mt-10">
                            <img src="./img/icon/MujazLogo.png" srcSet="./img/icon/MujazLogo.png 2x" alt="" width='60' />
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="wrapper bg-light">
                <div className="container pb-14 pb-md-16">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto d-flex justify-content-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/mujaz-platform.appspot.com/o/mujaz-logos%2FMujaz%20Platform%20Logo-03.png?alt=media&token=d8b11da0-336e-49af-912a-2b037dd37212" width="100" />
                        </div>
                    </div>
                </div>
            </section>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>
    );
};

const dispatchCompany = (dispatch) => {
    return {
        setCompany: (company, id, data, loggedIn) => { dispatch({ type: 'SET_COMPANY', value: company, id: id, data: data, loggedIn: loggedIn }) }
    }
}

export default connect(null, dispatchCompany)(Signup);
