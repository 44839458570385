import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Paper,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect, useRef } from 'react';
import { generateKey } from '../../utils';
import { makeStyles } from "tss-react/mui";
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Preview from '../../components/Preview';


const CurrencyRow = ({ onChange, onDelete, id, item }) => {
    const [currency, setCurrency] = useState(
        item
            ? item
            : {
                key: id,
                name: '',
                icon: '',
                value: 0,
            }
    );

    useEffect(() => {
        onChange(currency);
    }, [currency]);

    return (
        <Stack spacing={1} direction="row" alignItems="center">
            <Autocomplete
                options={[
                    '🇪🇺',
                    '🇬🇧',
                    '🇺🇸',
                    '🇹🇷',
                    '🇱🇧',
                    '🇸🇾',
                    '🇮🇶',
                    '🇵🇸',
                    '🇪🇬',
                    '🇸🇩',
                    '🇩🇿',
                    '🇹🇳',
                    '🇲🇦',
                    '🇱🇾',
                    '🇯🇴',
                    '🇮🇷',
                    '🇷🇺',
                    '🇸🇦',
                    '🇰🇼',
                    '🇾🇪',
                    '🇴🇲',
                    '🇧🇭',
                    '🇦🇪',
                    '🇶🇦',
                ]}
                disableClearable
                onChange={(e, icon) => setCurrency({ ...currency, icon })}
                renderInput={(params) => <TextField {...params} label="العلم" />}
                value={currency.icon}
            />
            <TextField
                fullWidth
                label="البلد"
                placeholder="البلد"
                variant="outlined"
                value={currency.name}
                onChange={({ target: { value } }) => setCurrency({ ...currency, name: value })}
            />
            <TextField
                label="القيمة"
                placeholder="القيمة"
                fullWidth
                variant="outlined"
                value={currency.value}
                onChange={({ target: { value } }) => setCurrency({ ...currency, value: value })}
            />
            <IconButton onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
        </Stack>
    );
};

const ConfirmCurrenciesCard = ({ props, defaultValue, handleCancelConfirmCard, handleEditConfirmCurrenciesCard }) => {
    const [currencies, setCurrencies] = useState(
        defaultValue
            ? defaultValue.items
            : [
                {
                    key: generateKey(),
                    name: '',
                    icon: '',
                    value: '',
                },
            ]
    );
    const previewCompRef = useRef();
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                padding: '20px'

            },
            paper: {
                padding: '20px',
            },
            cancelBtn: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmBtn: {
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            content: {
                background: '#fff',
                borderRadius: '25px',
                padding: '25px',
            }
        })
    );
    const PreviewFn = () => {
        setItems([{
            items: currencies,
            category: 'أسعار المال',
            title: 'المال',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }
    const { classes } = useStyles();

    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);


    return (
        <>
            <Grid container spacing={4} mt={1} style={{ marginRight: 0, width: '100%' }}>
                <Grid item xs={6} className={classes.content}>
                    <Stack spacing={3} direction="column">
                        <Typography variant="h5">اسعار الصرف</Typography>
                        <Stack direction="column" divider={<Divider />} spacing={2}>
                            {currencies.map((city, index) => (
                                <CurrencyRow
                                    key={city.key}
                                    id={city.key}
                                    onChange={(newCity) => {
                                        let temp = [...currencies];
                                        temp.splice(index, 1, newCity);
                                        setCurrencies(temp);
                                    }}
                                    item={city}
                                    onDelete={() => {
                                        let temp = [...currencies];
                                        temp.splice(index, 1);
                                        setCurrencies(temp);
                                    }}
                                />
                            ))}
                        </Stack>
                        <Button
                            fullWidth
                            onClick={() =>
                                setCurrencies([
                                    ...currencies,
                                    {
                                        key: generateKey(),
                                        name: '',
                                        icon: '',
                                        value: '',
                                    },
                                ])
                            }
                        >
                            <AddCircleOutlineIcon />
                        </Button>
                    </Stack>

                </Grid>
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
                <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={handleCancelConfirmCard}
                        >
                            رجوع
                        </Button>
                        <Button
                            className={classes.confirmBtn}
                            variant="contained"
                            onClick={e => handleEditConfirmCurrenciesCard(currencies, defaultValue.date)}
                        >
                            تأكيد
                        </Button>
                        <Button variant="contained" className={classes.previewBtn} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>

    );
};

export default ConfirmCurrenciesCard;
