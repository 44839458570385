import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
	deleteObject,
} from 'firebase/storage';
import { generateKey } from '../utils';
import pdfImage from '../img/icons/pdf.png';
// import { PDFReader } from 'reactjs-pdf-reader';
const PdfUploader = ({ image, onChange, background }) => {
	// Create a root reference
	const storage = getStorage();
	const NEWS_CARD_WITH_IMAGE = 'newsletter_image';
	const [file, setFile] = useState(image ? image : '');
	const bg = background ? background : '#fff';
	console.log(bg);
	const { getRootProps, getInputProps } = useDropzone({
		accept: 'application/pdf',
		multiple: false,
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			if (acceptedFiles.length) {
				console.log(acceptedFiles);
				const extension = acceptedFiles[0].name.split('.').pop();
				const newName = generateKey();
				uploadFile(acceptedFiles[0], `${newName}.${extension}`);
			}
		},
	});

	useEffect(() => {
		onChange(file);
	}, [file]);

	const uploadFile = (currentFile, name) => {
		// Create a reference to 'images/mountains.jpg'
		const storageRef = ref(storage, `pdf/${name}`);
		// While the file names are the same, the references point to different files
		const uploadTask = uploadBytesResumable(storageRef, currentFile);

		// Register three observers:
		// 1. 'state_changed' observer, called any time the state changes
		// 2. Error observer, called on failure
		// 3. Completion observer, called on successful completion
		uploadTask.on(
			'state_changed',
			(snapshot) => {
				// Observe state change events such as progress, pause, and resume
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log('Upload is ' + progress + '% done');
				switch (snapshot.state) {
					case 'paused':
						console.log('Upload is paused');
						break;
					case 'running':
						console.log('Upload is running');
						break;
					default:
						break;
				}
			},
			(error) => {
				// Handle unsuccessful uploads
				console.log(error);
			},
			() => {
				// Handle successful uploads on complete
				// For instance, get the download URL: https://firebasestorage.googleapis.com/...
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setFile(downloadURL);
				});
			}
		);
	};

	const deleteFile = () => {
		const storageRef = ref(storage, file);

		// Delete the file
		deleteObject(storageRef)
			.then(() => {
				setFile('');
			})
			.catch((error) => {
				// Uh-oh, an error occurred!
			});
	};

	return (
		<Grid
			container
			spacing={0}
			style={{ minHeight: 100 }}
			alignContent="center"
			justifyContent="center"
			alignItems="center"
		>
			{Boolean(file) && (
				<Grid item xs={12} style={{ background: bg, display: 'flex', justifyContent: 'space-around' }}>
					<div style={{ position: 'absolute' }}>
						<IconButton varaint="outlined" color="error" onClick={deleteFile}>
							<Delete />
						</IconButton>
					</div>
					<img src={pdfImage} style={{ width: '100%' }} className={NEWS_CARD_WITH_IMAGE} />
					{/* <PDFReader url={file} /> */}
				</Grid>
			)}
			{!Boolean(file) && (
				<Grid item xs={12} {...getRootProps({ className: 'dropzone' })}>
					<input {...getInputProps()} />
					<Button fullWidth color="inherit" variant="outlined" style={{ height: 100 }}>
						قم/قومي بسحب وإسقاط ال بي دي أف هنا ، أو انقر/ي لتحديد ال بي دي أف
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default PdfUploader;
