import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { getFirestore, addDoc, getDocs, query, where, collection } from 'firebase/firestore';
import mobilePhone from "../img/phone.png";
import SocialHolder from '../atoms/SocialHolder';
import { connect } from 'react-redux';

const Footer = (props) => {
  const { company, id, data } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [currentYear, setCurrentYear] = useState("");

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();

    setCurrentYear(year);

  }, []);

  const db = getFirestore();

  const useStyles = makeStyles()(
    () => ({
      footer: {
        width: '100%',
        borderTopRightRadius: 50,
        borderTopLeftRadius: 50,
        zIndex: 999,
        marginTop: 40,
        background: data?.settings?.view?.primaryColor?.hex ? data.settings.view.primaryColor.hex : '#ff6633',
        display: 'flex',
        flexShrink: 0,
        padding: '20px',
        textAlign: 'center',
        position: 'sticky',
        bottom: 0,
        justifyContent: 'center',
        '@media only screen and (max-width: 600px)': {
          padding: '15px',
        },
      },
      container: {
        maxWidth: '100%',
        padding: "0 70px",
        color: '#fff',
      },
      copyright: {

        '@media only screen and (max-width: 600px)': {
          fontSize: '0.7em'
        },
        '@media (min-width: 1536px)': {
          flexBasis: '70%',
          flexGrow: 0,
          maxWidth: '70%',
        }
      },
      socialIcon: {
        '&>svg': {
          minWidth: 20,
          '@media only screen and (max-width: 600px)': {
            marginRight: '15px'
          },
        }
      }
    })
  );

  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Grid container justifyContent={"space-around"} alignItems={'center'} className={classes.container}>
        <Grid item xs={8}>
          <Typography className={classes.copyright}>جميع الحقوق محفوظة موجز ميديا © {currentYear}</Typography>
        </Grid>
        <Grid item xs={3}>
          <SocialHolder fill={"#fff"} className={classes.socialIcon} />
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => {
  console.log(state);
  return state
}

export default connect(mapStateToProps)(Footer);
