import { Button, Container, Grid, IconButton, Stack, Divider, Avatar, Typography } from '@mui/material';
import { Hidden } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Navigation } from 'react-minimal-side-navigation';
// import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import NewsletterIcon from '@mui/icons-material/Newspaper';
import { makeStyles } from "tss-react/mui";
import Categories from '../pages/Dashboard/Categories';
import penIcon from "../img/icons/editorial tool editorial too V2.png";
//import sectionsIcon from "../img/icons/1-09.png";
import sectionsIcon from "../img/icons/Categories V2.png";
import templates from "../img/icons/templates V2.png";
import newsLibraryIcon from "../img/icons/content library V2.png";
//import prevNewslettersIcon from "../img/icons/1-03.png";
import prevNewslettersIcon from "../img/icons/old newsletters V2.png";
import subsListIcon from "../img/icons/subscribers list V2.png";
import adsIcon from "../img/icons/ads V2.png";
import statIcon from "../img/icons/analytics V1.png";
import usersIcon from "../img/icons/1-06.png";
import addNewsIcon from "../img/icons/1-15.png";
import addborsaIcon from "../img/icons/1-16.png";
import chatGPTIcon from "../img/icons/1-17.png";
import addImageIcon from "../img/icons/1-12.png";
import addWeatherIcon from "../img/icons/1-11.png";
import addCurrencyIcon from "../img/icons/1-10.png";
import SettingsIcon from '../img/icons/old/setting.png';
import whatsAppIcon from '../img/icons/whatsapp-Icons.png'
//import Logo from '../img/icons/logo-01.png';
import Logo from '../img/icons/Mujaz Platform Logo-02.png';
// import logo from '../img/pic-03.jpg';
import { connect } from 'react-redux';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import NewsWithImage from '../pages/Dashboard/NewsWithImage';

const SideBarMenu = (props) => {
	const history = useHistory();
	const location = useLocation();
	const { toggleSidebar, collapseSidebar, rtl } = useProSidebar();
	const useStyles = makeStyles()(
		() => ({

			sidebarContainer: {
				direction: 'ltr',
			},
			divider: {
				height: 3,
				background: '#666',
				margin: '25px 0',
			},
			avatar: {
				width: '175px',
				height: '175px',
				border: '1px solid #e6e6e6',
				margin: '0 auto',
				padding: '10px'
			},
			avatarName: {
				fontWeight: 'bold',
				color: '#000',
				alignText: 'center',
			},
			avatarPosition: {
				alignText: 'center',
				color: '#979797',
			},
			backgroundBlue: {
				background: "#072448"
			}

		})
	);
	const { company, data, loggedInUser } = props;
	const { classes } = useStyles();
	return (

		<div style={{ display: 'flex', minHeight: '100%', overflowY: 'hidden', }} >

			<Sidebar
				rtl
				width='100%'
				breakPoint='xs'
				backgroundColor="#072448"
				customBreakPoint="800px"
				rootStyles={{
					['.ps-rtl']: {
						border: 'none !important'
					},
					['.ps-sidebar-container']: {
						borderBottomLeftRadius: '50px',
						borderTopLeftRadius: '50px'
					}
				}}
			>
				<Grid container justifyContent="space-around" mt={4} mb={4} columns={{ xs: 1, sm: 1, md: 1 }}>
					{/* Show the logo on large screens */}
					<Grid item xs={1} sm={1} md={1} style={{ display: 'flex', justifyContent: 'space-around' }} >
						<Link to="/dashboard">
							<img src={Logo} width={180} />
						</Link>

					</Grid>

					{data?.settings?.senderSettings?.defaultReplyTo != '' &&
						<Grid item mt={8} xs={1} sm={1} md={1} style={{ display: 'flex', justifyContent: 'space-around' }}>
							<Button
								variant="contained"
								size="large"
								style={{ fontSize: '14px', boxShadow: 'none', background: "#FFCB00", color: "#072448" }}
								// className={classes.singleBtn}
								//endIcon={<NewsletterIcon />}
								component={Link}
								to="/createnewsletter"
							>
								إنشاء نشرة جديدة
							</Button>
						</Grid>}
				</Grid>


				<Menu rootStyles={{
					['.ps-menu-button']: {
						color: 'white',
						fontSize: '14px',
						paddingRight: '35px',
						background: "#072448",
						backgroundRepeat: 'no-repeat',
						transition: 'background-size .3s ease-in-out',
						textDecoration: 'none',
						backgroundSize: '0 100%',
						'&:hover': {
							backgroundSize: '100% 100%',
							backgroundImage: 'linear-gradient(rgba(220,246,246, 0.5), rgba(220,246,246, 0.5))',
							backgroundColor: '#072448',
							borderLeft: '5px solid #54D2D2',
						},
					},
				}}>
					<MenuItem
						icon={<img src={whatsAppIcon} width={29}/>} component={<Link to="#" />}
						style={{
							color: '#FFCB00', 
						}} >
						إرسال عبر واتس آب (قريبا)
					</MenuItem>
					<MenuItem icon={<img src={penIcon} width={25} />} component={<Link to="/news" />}>
						أدوات التحرير
					</MenuItem>

					{/* <SubMenu label="أدوات التحرير" icon={<img src={penIcon} background={"#072448"} width={40} />} >
						<MenuItem icon={<img src={addNewsIcon} width={40} />} component={<Link to="/newswithimage" />}>
							إضافة خبر
						</MenuItem>
						<MenuItem icon={<img src={addborsaIcon} width={40} />} component={<Link to="/newsstocks" />}>
							إضافة البورصة
						</MenuItem>
						<MenuItem icon={<img src={addImageIcon} width={40} />} component={<Link to="/newsimage" />}>
							إضافة صورة
						</MenuItem> */}
					{/* <MenuItem icon={<img src={addImageIcon} width={40} />} component={<Link to="/newspdf" />}>
							إضافة بي دي أف
						</MenuItem> */}
					{/* <MenuItem icon={<img src={addWeatherIcon} width={40} />} component={<Link to="/newsweather" />}>
							إضافة الطقس
						</MenuItem>
						<MenuItem icon={<img src={addCurrencyIcon} width={40} />} component={<Link to="/newscurrency" />}>
							إضافة العملات
						</MenuItem>
						<MenuItem icon={<img src={chatGPTIcon} width={40} />} component={<Link to="/chatgpt" />}>
							مساعد موجز الرقمي
						</MenuItem>
					</SubMenu> */}

					<MenuItem icon={<img src={sectionsIcon} width={25} />} component={<Link to="/categories" />}>
						الفئات
					</MenuItem>
					<MenuItem icon={<img src={newsLibraryIcon} width={25} />} component={<Link to="/newslibrary" />}>
						مكتبة المحتوى
					</MenuItem>
					<MenuItem icon={<img src={templates} width={25} />}  >
						القوالب (قريبا)
					</MenuItem>
					<MenuItem icon={<img src={prevNewslettersIcon} width={25} />} component={<Link to="/archive" />}>
						النشرات السابقة
					</MenuItem>

					{loggedInUser.responsibility == 'manager' &&
						<>
							<MenuItem icon={<img src={subsListIcon} width={25} />} component={<Link to="/subscribers" />}>
								قوائم المشتركين
							</MenuItem>
							<MenuItem icon={<img src={adsIcon} width={25} />} component={<Link to="/sponsor/list" />}>
								الإعلانات
							</MenuItem>
							<MenuItem icon={<img src={statIcon} width={25} />} component={<Link to="/statistics" />}>
								الإحصائيات
							</MenuItem>
							{/* <MenuItem icon={<img src={usersIcon} width={40} />} component={<Link to="/users" />}>
								إدارة المستخدمين
							</MenuItem> */}
							<MenuItem icon={<img src={SettingsIcon} width={40} />} component={<Link to="/settings" />}>
								إعدادات الحساب
							</MenuItem>
						</>
					}

				</Menu>
			</Sidebar>
			{/* <main>
				<button onClick={() => toggleSidebar()}>Collapse</button>
			</main> */}
		</div>


		// <>
		// 	{loggedInUser.responsibility != 'superadmin' &&
		// 		<>
		// 			{/* <Avatar
		// 				alt="Remy Sharp"
		// 				className={classes.avatar}
		// 				src={loggedInUser.logo}
		// 				sx={{ width: 56, height: 56 }}
		// 			/>
		// 			<Typography className={classes.avatarName} align="center" mt={1}>
		// 				{loggedInUser.name}
		// 			</Typography>
		// 			<Typography className={classes.avatarPosition} align="center">
		// 				{loggedInUser.responsibility}
		// 			</Typography> */}

		// 			<Navigation
		// 				className={classes.sidebarContainer}
		// 				// you can use your own router's api to get pathname
		// 				activeItemId=""
		// 				onSelect={({ itemId }) => {
		// 					// maybe push to the route
		// 					history.push(itemId);
		// 				}}
		// 				items={[
		// 					{

		// 						title: 'إنشاء جديد',
		// 						itemId: '#',
		// 						elemBefore: () => <img src={penIcon} />,
		// 						// you can use your own custom Icon component as well
		// 						// icon is optional

		// 						subNav: [
		// 							{
		// 								title: 'إضافة خبر',
		// 								itemId: '/newswithimage',
		// 								elemBefore: () => <img src={addNewsIcon} />,
		// 							},
		// 							{
		// 								title: 'إضافة البورصة',
		// 								itemId: '/newsstocks',
		// 								elemBefore: () => <img src={addborsaIcon} />,
		// 							},
		// 							{
		// 								title: 'إضافة صورة',
		// 								itemId: '/newsimage',
		// 								elemBefore: () => <img src={addImageIcon} />,
		// 							},
		// 							{
		// 								title: 'إضافة بي دي أف',
		// 								itemId: '/newspdf',
		// 								elemBefore: () => <img src={addImageIcon} />,
		// 							},
		// 							{
		// 								title: 'إضافة الطقس',
		// 								itemId: '/newsweather',
		// 								elemBefore: () => <img src={addWeatherIcon} />,
		// 							},
		// 							{
		// 								title: 'إضافة العملات',
		// 								itemId: '/newscurrency',
		// 								elemBefore: () => <img src={addCurrencyIcon} />,
		// 							},
		// 							// {
		// 							// 	title: 'مشاركة تغريدة',
		// 							// 	itemId: '/newstwitter',
		// 							// 	elemBefore: () => <img src={addCurrencyIcon} />,
		// 							// },
		// 							// {
		// 							// 	title: 'مشاركة منشور فايسبوك',
		// 							// 	itemId: '/newsfacebook',
		// 							// 	elemBefore: () => <img src={addCurrencyIcon} />,
		// 							// },
		// 							// {
		// 							// 	title: 'مشاركة منشور انستاغرام',
		// 							// 	itemId: '/newsinstagram',
		// 							// 	elemBefore: () => <img src={addCurrencyIcon} />,
		// 							// },
		// 							// {
		// 							// 	title: 'مشاركة يوتيوب',
		// 							// 	itemId: '/newsyoutube',
		// 							// 	elemBefore: () => <img src={addCurrencyIcon} />,
		// 							// },
		// 						],
		// 					},
		// 				]}
		// 			/>
		// 			<Divider variant="fullWidth" className={classes.divider} />
		// 			<Navigation
		// 				className={classes.sidebarContainer}
		// 				// you can use your own router's api to get pathname
		// 				activeItemId=""
		// 				onSelect={({ itemId }) => {
		// 					// maybe push to the route
		// 					history.push(itemId);
		// 				}}
		// 				items={[
		// 					{
		// 						title: 'الفئات',
		// 						itemId: '/categories',
		// 						elemBefore: () => <img src={sectionsIcon} />,
		// 					}
		// 				]}
		// 			/>
		// 			<Navigation
		// 				className={classes.sidebarContainer}
		// 				// you can use your own router's api to get pathname
		// 				activeItemId=""
		// 				onSelect={({ itemId }) => {
		// 					// maybe push to the route
		// 					history.push(itemId);
		// 				}}
		// 				items={[
		// 					{
		// 						title: 'مكتبة الأخبار',
		// 						itemId: '/newslibrary',
		// 						elemBefore: () => <img src={newsLibraryIcon} />,
		// 					}
		// 				]}
		// 			/>
		// 			<Navigation
		// 				className={classes.sidebarContainer}
		// 				// you can use your own router's api to get pathname
		// 				activeItemId=""
		// 				onSelect={({ itemId }) => {
		// 					// maybe push to the route
		// 					history.push(itemId);
		// 				}}
		// 				items={[
		// 					{
		// 						title: 'النشرات السابقة',
		// 						itemId: '/archive',
		// 						elemBefore: () => <img src={prevNewslettersIcon} />,
		// 					}
		// 				]}
		// 			/>
		// 			{loggedInUser.responsibility == 'manager' && <Navigation
		// 				className={classes.sidebarContainer}
		// 				// you can use your own router's api to get pathname
		// 				activeItemId=""
		// 				onSelect={({ itemId }) => {
		// 					// maybe push to the route
		// 					history.push(itemId);
		// 				}}
		// 				items={[
		// 					{
		// 						title: 'قائمة المشتركين',
		// 						itemId: '/subscribers',
		// 						elemBefore: () => <img src={subsListIcon} />,
		// 					}
		// 				]}
		// 			/>}
		// 			{loggedInUser.responsibility == 'manager' && <Navigation
		// 				className={classes.sidebarContainer}
		// 				// you can use your own router's api to get pathname
		// 				activeItemId=""
		// 				onSelect={({ itemId }) => {
		// 					// maybe push to the route
		// 					history.push(itemId);
		// 				}}
		// 				items={[
		// 					{
		// 						title: 'الرعاة',
		// 						itemId: '/sponsor',
		// 						elemBefore: () => <img src={sponsorsIcon} />,
		// 					}
		// 				]}
		// 			/>}
		// 			{loggedInUser.responsibility == 'manager' && <Navigation
		// 				className={classes.sidebarContainer}
		// 				// you can use your own router's api to get pathname
		// 				activeItemId=""
		// 				onSelect={({ itemId }) => {
		// 					// maybe push to the route
		// 					history.push(itemId);
		// 				}}
		// 				items={[
		// 					{
		// 						title: 'الإحصائيات',
		// 						itemId: '/statistics',
		// 						elemBefore: () => <img src={statIcon} />,
		// 					}
		// 				]}
		// 			/>}
		// 			{loggedInUser.responsibility == 'manager' && <Navigation
		// 				className={classes.sidebarContainer}
		// 				// you can use your own router's api to get pathname
		// 				activeItemId=""
		// 				onSelect={({ itemId }) => {
		// 					// maybe push to the route
		// 					history.push(itemId);
		// 				}}
		// 				items={[
		// 					{
		// 						title: 'إدارة المستخدمين',
		// 						itemId: '/users',
		// 						elemBefore: () => <img src={usersIcon} />,
		// 					},
		// 				]}
		// 			/>}
		// 			<Divider variant="fullWidth" className={classes.divider} />
		// 			{loggedInUser.responsibility == 'manager' && <Navigation
		// 				className={classes.sidebarContainer}
		// 				// you can use your own router's api to get pathname
		// 				activeItemId=""
		// 				onSelect={({ itemId }) => {
		// 					// maybe push to the route
		// 					history.push(itemId);
		// 				}}
		// 				items={[
		// 					{
		// 						title: 'إعدادات الحساب',
		// 						itemId: '/settings',
		// 						elemBefore: () => <img src={SettingsIcon} />,
		// 					}
		// 				]}
		// 			/>}

		// 		</>
		// 	}
		// 	{loggedInUser.responsibility === 'superadmin' &&
		// 		<>
		// 			<Avatar
		// 				alt="Remy Sharp"
		// 				className={classes.avatar}
		// 				src={loggedInUser.logo}
		// 				sx={{ width: 56, height: 56 }}
		// 			/>
		// 			<Typography className={classes.avatarName} align="center" mt={1}>
		// 				{loggedInUser.name}
		// 			</Typography>
		// 			<Typography className={classes.avatarPosition} align="center">
		// 				{loggedInUser.responsibility}
		// 			</Typography>

		// 			<Navigation
		// 				className={classes.sidebarContainer}
		// 				// you can use your own router's api to get pathname
		// 				activeItemId=""
		// 				onSelect={({ itemId }) => {
		// 					// maybe push to the route
		// 					history.push(itemId);
		// 				}}
		// 				items={[
		// 					{
		// 						title: 'الشركات',
		// 						itemId: '/admin/companies',
		// 						elemBefore: () => <img src={sectionsIcon} />,
		// 					}
		// 				]}
		// 			/>
		// 		</>
		// 	}

		// </>

	);
};
const mapStateToProps = (state) => {
	return state
}


export default connect(mapStateToProps)(SideBarMenu);
