import React, { useState, useEffect } from 'react';
import { Stack, TextField, IconButton, Divider, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "tss-react/mui";
import trash from '../img/icons/trash.svg'

const StockRow = ({ onChange, onDelete, id, item }) => {
	const [stock, setStock] = useState(
		item
			? item
			: {
				key: id,
				name: '',
				value: 0,
				change: 0,
			}
	);

	useEffect(() => {
		onChange(stock);
	}, [stock]);

	const useStyles = makeStyles()(
		() => ({
			fieldStyle: {
				'& .MuiInputBase-root': {
					fontSize: '0.7rem', // Smaller font size
				},
				'& .MuiInputLabel-root': {
					fontSize: '0.7rem', // Smaller label font size
				},
				'& .MuiOutlinedInput-root': {
					borderRadius: '10px'
				},
				"& fieldset": {
					border: 'none',
				},
			},

		}))

	const { classes } = useStyles();

	const arrows = {
		positive: '🟢',
		negative: '🔴',
		zero: '🔵',
	};

	const handleChange = (event) => {
		const { value } = event.target;
		setStock({ ...stock, change: value });
	};


	return (
		<Stack spacing={0.5} divider={<Divider />} justifyContent="center" direction="row" alignItems="center">
			<Box>
				{
					arrows[
					stock.change.indexOf('+') > 0
						? 'positive'
						: stock.change.indexOf('-') > 0
							? 'negative'
							: 'zero'
					]
				}
			</Box>
			<TextField
				fullWidth
				placeholder="اسم السهم"
				variant="outlined"
				value={stock.name}
				onChange={({ target: { value } }) => setStock({ ...stock, name: value })}
				className={classes.fieldStyle}
			/>
			<TextField
				placeholder="قيمة السهم"
				fullWidth
				variant="outlined"
				value={stock.value == 0 ? '' : stock.value}
				onChange={({ target: { value } }) =>
					setStock({ ...stock, value: value })}
				className={classes.fieldStyle}
			/>
			<TextField
				placeholder="نسبة التغيير"
				fullWidth
				variant="outlined"
				color={stock.change > 0 ? 'success' : stock.change < 0 ? 'error' : 'info'}
				value={stock.change == 0 ? '' : stock.change}
				onChange={({ target: { value } }) => {
					setStock({ ...stock, change: value });
				}}
				className={classes.fieldStyle}
			/>
			{/* <IconButton color='primary' onClick={onDelete}>
				<DeleteIcon />
			</IconButton> */}
			<IconButton size='small' aria-label="delete"  onClick={onDelete}>
				<img src={trash} alt="Trash Logo" style={{ width: '24px', height: '24px', }} />
			</IconButton>
		</Stack>
	);
};

export default StockRow;
