import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
	deleteObject,
} from 'firebase/storage';
import { generateKey } from '../utils';

const ImageUploader = ({ image, textImage, onChange, background}) => {
	// Create a root reference
	const storage = getStorage();
	const NEWS_CARD_WITH_IMAGE = 'newsletter_image';
	const [file, setFile] = useState(image ? image : '');
	const [text, setText] = useState(textImage ? textImage : 'قم/قومي بسحب وإسقاط الصورة هنا ، أو انقر/ي لتحديد الصورة')
	const bg = background ? background : '#fff';
	console.log(bg);
	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		multiple: false,
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			if (acceptedFiles.length) {
				const extension = acceptedFiles[0].name.split('.').pop();
				const newName = generateKey();
				uploadFile(acceptedFiles[0], `${newName}.${extension}`);
			}
		},
	});

	useEffect(() => {
		onChange(file);
	}, [file]);

	const uploadFile = (currentFile, name) => {
		// Create a reference to 'images/mountains.jpg'
		const storageRef = ref(storage, `images/${name}`);
		// While the file names are the same, the references point to different files
		const uploadTask = uploadBytesResumable(storageRef, currentFile);

		// Register three observers:
		// 1. 'state_changed' observer, called any time the state changes
		// 2. Error observer, called on failure
		// 3. Completion observer, called on successful completion
		uploadTask.on(
			'state_changed',
			(snapshot) => {
				// Observe state change events such as progress, pause, and resume
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log('Upload is ' + progress + '% done');
				switch (snapshot.state) {
					case 'paused':
						console.log('Upload is paused');
						break;
					case 'running':
						console.log('Upload is running');
						break;
					default:
						break;
				}
			},
			(error) => {
				// Handle unsuccessful uploads
				console.log(error);
			},
			() => {
				// Handle successful uploads on complete
				// For instance, get the download URL: https://firebasestorage.googleapis.com/...
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setFile(downloadURL);
					console.log('download URL : ' + downloadURL)
					
				});
			}
		);
	};

	const deleteFile = () => {
		const storageRef = ref(storage, file);

		// Delete the file
		deleteObject(storageRef)
			.then(() => {
				setFile('');
			})
			.catch((error) => {
				// Uh-oh, an error occurred!
			});
	};

	return (
		<Grid
			container
			spacing={0}
			//style={{ minHeight: 100 }}
			alignContent="center"
			justifyContent="center"
			alignItems="center"
		>
		
			
				<Grid item xs={12} {...getRootProps({ className: 'dropzone' })}>
					<input {...getInputProps()} />
					<Button
						size='small'
						style={{color: '#FFF', width:'100%', backgroundColor: '#FF6150', borderRadius: '15px', padding: '12px' }}
						variant="contained"
					>
						{text}
					</Button>
				</Grid>
		
		</Grid>
	);
};

export default ImageUploader;
