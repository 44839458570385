import React, { useEffect, useState } from 'react';
import {
    Button,
    Stack,
    Grid,
    Paper,
    TextField,
    Typography,
    Divider,
    Snackbar,
    Checkbox
} from '@mui/material';
import ImageUploader from '../../../components/ImageUploader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from "tss-react/mui";
import { ChromePicker } from 'react-color';
import { doc, addDoc, deleteDoc, getDoc, documentId, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { connect } from 'react-redux';

const AddCompany = ({ props, buttonName, companyName, id, index, handleAddCompany }) => {
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [website, setWebsite] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [sender, setSender] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    
    const [toPreview, setToPreview] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFF');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [logo, setLogo] = useState('');
    const [footerLogo, setFooterLogo] = useState('');
    const [footerText, setFooterText] = useState('');
    const [footerSlogonText, setFooterSlogonText] = useState('');
    const [footerCopyrightText, setFooterCopyrightText] = useState('');
    const [footerUnsubscribeText, setFooterUnsubscribeText] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [youtube, setYoutube] = useState('');

    const [displayPrimaryColorPicker, setDisplayPrimaryColorPicker] = useState(false);
    const [displaySecondaryColorPicker, setDisplaySecondaryColorPicker] = useState(false);
    // const {  data } = props;
    const accountData =
    {
        personalProfile: {
            personalData:
            {
                username: username,
                name: name,
                email: email
            },
            companyData:
            {
                company: company,
                website: website,
                address: address,
                city: city,
                country: country,
            }
        },
        settings:
        {
            senderSettings:
            {
                name: sender,
                email: senderEmail,
                toPreview: toPreview,
                defaultFrom: sender + ' <' + senderEmail + '>',
                defaultReplyTo: senderEmail,
                smtp_connection: '',
                apiKey: ''
            },
            view:
            {
                logo: logo,
                primaryColor: primaryColor,
                secondaryColor: secondaryColor,
            },
            footer:
            {
                footerLogo: footerLogo,
                text: footerText,
                slogon: footerSlogonText,
                copyrightText: footerCopyrightText,
                unsubscribeText: footerUnsubscribeText,
                socialMedia:
                {
                    facebook: facebook,
                    instagram: instagram,
                    twitter: twitter,
                    youtube: youtube
                }
            }
        }

    }


    const [tabValue, setTabValue] = React.useState('الملف الشخصي');
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;




    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '20px 30px',
                marginBottom: 15,
                fontWeight: 400
            },
            TabList: {
                display: 'flex',
                justifyContent: 'space-around',
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px 0px 30px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '2px 2px 10px #ccc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
                padding: '5px 25px',
                borderRadius: '30px',
                ':hover': {
                    background: '#FFF',
                    color: '#f63',
                }
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }

            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '25px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '5px 5px 5px #ccc',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },

            confirmBtnCancel: {
                // color: '#ff6633',
            },
            btnSecondary: {
                borderRadius: 30,
                backgroundColor: '#fff',
                color: '#ff6633',
                borderRadius: '30px',
                ':hover': {
                    backgroundColor: '#ff6633',
                    color: '#FFF',
                },
            },
            saveBtn: {
                padding: '5px 40px'
            }

        })
    );

    const { classes } = useStyles();

    const handleChangeTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    const handlePrimaryColorClick = () => {
        setDisplayPrimaryColorPicker(displayColorPicker => !displayColorPicker);
    };

    const handlePrimaryColorClose = () => {
        setDisplayPrimaryColorPicker(false);
    };
    const handleSecondaryColorClick = () => {
        setDisplaySecondaryColorPicker(displayColorPicker => !displayColorPicker);
    };

    const handleSecondaryColorClose = () => {
        setDisplaySecondaryColorPicker(false);
    };
    
    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
    const getDetails = () => {

        // setName(data.personalProfile.personalData.name);
        // setUsername(data.personalProfile.personalData.username);
        // setAddress(data.personalProfile.companyData.address);
        // setCity(data.personalProfile.companyData.city);
        // setCountry(data.personalProfile.companyData.country);
        // setWebsite(data.personalProfile.companyData.website);
        // setCompany(data.personalProfile.companyData.company);

        // setSender(data.settings.senderSettings.name);
        // setSenderEmail(data.settings.senderSettings.email);
        // setToPreview(data.settings.senderSettings.toPreview);

        // setPrimaryColor(data.settings.view.primaryColor);
        // setSecondaryColor(data.settings.view.secondaryColor);
        // setLogo(data.settings.view.logo);
        // setFooterLogo(data.settings.footer.footerLogo);
        // setFooterText(data.settings.footer.text);
        // setFooterSlogonText(data.settings.footer.slogon);
        // setFooterCopyrightText(data.settings.footer.copyrightText);
        // setFooterUnsubscribeText(data.settings.footer.unsubscribeText);
        // setFacebook(data.settings.footer.socialMedia.facebook);
        // setInstagram(data.settings.footer.socialMedia.instagram);
        // setTwitter(data.settings.footer.socialMedia.twitter);
        // setYoutube(data.settings.footer.socialMedia.youtube);
    };

    useEffect(() => {
        // getDetails();
    }, [company, id]);



    return (
        <>

            <Grid container>
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paperList}>
                        <Grid item xs={9}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                إعدادات الحساب
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Stack sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                                <TabList onChange={handleChangeTabValue} aria-label="lab API tabs example" centered className={classes.TabList}>
                                    <Tab label="الملف الشخصي" value="الملف الشخصي" />
                                    <Tab label="إعدادات النشرة" value="إعدادات النشرة" />
                                    <Tab label="الرصيد والدفع" value="3" />
                                    <Tab label="الربط والتضمين" value="4" />
                                </TabList>
                            </Box>
                            <TabPanel value="الملف الشخصي" style={{ padding: '24px 0' }}>
                                <Paper elevation={3} style={{ padding: '30px' }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>المستخدم الرئيسي</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} mt={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                value={username}
                                                onChange={({ target: { value } }) => setUsername(value)}
                                                variant="outlined"
                                                label="البريد الإلكتروني/إسم المستخدم"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                value={name}
                                                onChange={({ target: { value } }) => setName(value)}
                                                variant="outlined"
                                                label="الإسم"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ borderBottomWidth: 3, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>معلومات الشركة</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={6} mt={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                value={company}
                                                onChange={({ target: { value } }) => setCompany(value)}
                                                variant="outlined"
                                                label="إسم الشركة/المنطمة"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={6}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                value={website}
                                                onChange={({ target: { value } }) => setWebsite(value)}
                                                variant="outlined"
                                                label="الموقع الإلكتروني"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={6}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={address}
                                                onChange={({ target: { value } }) => setAddress(value)}
                                                variant="outlined"
                                                label="العنوان"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={6}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                value={city}
                                                onChange={({ target: { value } }) => setCity(value)}
                                                variant="outlined"
                                                label="المدينة"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                value={country}
                                                onChange={({ target: { value } }) => setCountry(value)}
                                                variant="outlined"
                                                label="الدولة"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ borderBottomWidth: 3, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                                    <Grid container mt={4}>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="contained" className={classes.saveBtn} onClick={e => handleAddCompany(accountData)}>حفظ</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </TabPanel>
                            <TabPanel value="إعدادات النشرة" style={{ padding: '24px 0' }}>
                                <Paper elevation={3} style={{ padding: '30px' }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>إعدادات المرسل</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} mt={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                value={sender}
                                                onChange={({ target: { value } }) => setSender(value)}
                                                variant="outlined"
                                                label="اسم المرسل"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                value={senderEmail}
                                                onChange={({ target: { value } }) => setSenderEmail(value)}
                                                variant="outlined"
                                                label="البريد الإلكتروني للمرسل"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={toPreview}
                                                    onChange={() => setToPreview(value => !value)}
                                                />
                                            } label="عرض اسم المرسل في خانة to عند المستلم" />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ borderBottomWidth: 3, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                                    <Grid container spacing={4} mt={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>المظهر</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} style={{ justifyContent: 'space-evenly' }}>
                                        <Grid item xs={4}>
                                            <Typography variant="h6">شعار الشركة</Typography>
                                            <Typography variant="p">سيتم عرض هذا الشعار في اي مكان لديك فيه عنصر يدل على الهوية مثل ترويسات وتذييل والحساب الرئيسي.</Typography>
                                            <ImageUploader image={logo} onChange={setLogo} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="h6">شعار التذييل</Typography>
                                            <Typography variant="p">سيتم عرض هذا الشعار في اي مكان لديك فيه عنصر يدل على الهوية مثل ترويسات وتذييل والحساب الرئيسي.</Typography>
                                            <ImageUploader background={"#eee"} image={footerLogo} onChange={setFooterLogo} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} mt={2}>
                                        <Grid item xs={3}>
                                            <Typography variant="h6">اللون الرئيسي</Typography>
                                            <Button style={{ background: primaryColor.hex, width: '150px', height: '40px' }} size="large" variant="outlined" onClick={handlePrimaryColorClick}>  </Button>
                                            {displayPrimaryColorPicker ? <div style={popover}>
                                                <div style={cover} onClick={handlePrimaryColorClose} />
                                                <ChromePicker
                                                    style={{ direction: 'ltr' }}
                                                    color={primaryColor}
                                                    onChange={(color) => setPrimaryColor(color)} />
                                            </div> : null}

                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="h6">اللون الثانوي</Typography>
                                            <Button style={{ background: secondaryColor.hex, width: '150px', height: '40px' }} size="large" variant="outlined" onClick={handleSecondaryColorClick}>  </Button>
                                            {displaySecondaryColorPicker ? <div style={popover}>
                                                <div style={cover} onClick={handleSecondaryColorClose} />
                                                <ChromePicker
                                                    style={{ direction: 'ltr' }}
                                                    color={secondaryColor}
                                                    onChange={(color) => setSecondaryColor(color)} />
                                            </div> : null}

                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ borderBottomWidth: 3, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>التذييل</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mt={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={footerText}
                                                onChange={({ target: { value } }) => setFooterText(value)}
                                                variant="outlined"
                                                label="نص التذييل"
                                                margin="normal"
                                                multiline
                                                rows={2}
                                                maxRows={6}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={footerSlogonText}
                                                onChange={({ target: { value } }) => setFooterSlogonText(value)}
                                                variant="outlined"
                                                label="نص شعار التذييل"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={footerCopyrightText}
                                                onChange={({ target: { value } }) => setFooterCopyrightText(value)}
                                                variant="outlined"
                                                label="نص الحقوق"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={footerUnsubscribeText}
                                                onChange={({ target: { value } }) => setFooterUnsubscribeText(value)}
                                                variant="outlined"
                                                label="نص إلغاء الإشتراك"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={4} mt={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">التواصل الإجتماعي</Typography>
                                            <TextField
                                                fullWidth
                                                value={facebook}
                                                onChange={({ target: { value } }) => setFacebook(value)}
                                                variant="outlined"
                                                label="فايسبوك"
                                                margin="normal"
                                            />
                                            <TextField
                                                fullWidth
                                                value={instagram}
                                                onChange={({ target: { value } }) => setInstagram(value)}
                                                variant="outlined"
                                                label="إنستاغرام"
                                                margin="normal"
                                            />
                                            <TextField
                                                fullWidth
                                                value={twitter}
                                                onChange={({ target: { value } }) => setTwitter(value)}
                                                variant="outlined"
                                                label="تويتر"
                                                margin="normal"
                                            />
                                            <TextField
                                                fullWidth
                                                value={youtube}
                                                onChange={({ target: { value } }) => setYoutube(value)}
                                                variant="outlined"
                                                label="يوتيوب"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ borderBottomWidth: 3, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                                    <Grid container mt={4}>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="contained" className={classes.saveBtn} onClick={e => handleAddCompany(accountData)}>حفظ</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </TabPanel>
                            <TabPanel value="3">الرصيد والدفع</TabPanel>
                            <TabPanel value="4">الربط والتضمين</TabPanel>
                        </TabContext>
                    </Stack>
                </Grid>
            </Grid>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>

    );
};


const mapStateToProps = (state) => {
    console.log(state);
    return state
}
const dispatchData = (dispatch) => {
    return {
        setData: (data) => { dispatch({ type: 'SET_DATA', data: data }) }
    }
}
export default connect(mapStateToProps, dispatchData)(AddCompany);
