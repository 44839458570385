import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
	deleteObject,
} from 'firebase/storage';
import { generateKey } from '../utils';

const ImageUploaderSetting = (props) => {
	// Firebase storage reference
	const { image, textImage, onChange, background, yazbek } = props;
	const storage = getStorage();
	const NEWS_CARD_WITH_IMAGE = 'newsletter_image';
	const [file, setFile] = useState(image || '');
	const [text, setText] = useState(textImage || 'قم/قومي بسحب وإسقاط الصورة هنا ، أو انقر/ي لتحديد الصورة');
	const bg = background || '#fff';

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		multiple: false,
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			if (acceptedFiles.length) {
				const extension = acceptedFiles[0].name.split('.').pop();
				const newName = generateKey();
				uploadFile(acceptedFiles[0], `${newName}.${extension}`);
			}
		},
	});

	// Call onChange whenever file state changes
	useEffect(() => {
		onChange(file);
	}, [file, onChange]);

	const uploadFile = (currentFile, name) => {
		const storageRef = ref(storage, `images/${name}`);
		const uploadTask = uploadBytesResumable(storageRef, currentFile);

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log('Upload is ' + progress + '% done');
			},
			(error) => {
				console.error('Upload failed: ', error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setFile(downloadURL);
				});
			}
		);
	};

	const deleteFile = () => {
		if (!file) return;

		const storageRef = ref(storage, file);

		// Delete the file from Firebase storage
		deleteObject(storageRef)
			.then(() => {
				setFile('');
			})
			.catch((error) => {
				console.error('Delete failed: ', error);
			});
	};

	return (
		<Grid
			container
			spacing={2}
			alignContent="center"
			justifyContent="center"
			alignItems="center"
		>
			{file ? (
				<Grid item xs={12} style={{ background: bg, position: 'relative' }}>
					<IconButton
						variant="outlined"
						color="error"
						style={{ position: 'absolute', top: '10px', right: '10px' }}
						onClick={deleteFile}
					>
						<Delete />
					</IconButton>
					<img src={file} alt="uploaded" style={{ width: '175px', height: '175px', borderRadius: '100px' }} />
				</Grid>
			) : (
				<Grid item xs={12} {...getRootProps({ className: 'dropzone' })}>
					<input {...getInputProps()} />
					<Button
						size="small"
						style={{
							background: '#fff',
							border: '1px solid black',
							color: 'black',
							fontSize: 'larger',
							borderRadius: '15px',
							padding: '12px',
						}}
					>
						{text}
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default ImageUploaderSetting;
