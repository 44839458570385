import React from 'react';
import { Grid, Typography } from '@mui/material';
import AnchorLink from 'react-anchor-link-smooth-scroll';
const FooterNavBar = () => {
	return (
		<footer id='contactus'>
			<hr className="mt-13 mt-md-14 mb-7" />
			<div className="container pb-7">
				<div className="d-lg-flex flex-row align-items-lg-center">
					<div className="col-md-3">
						<div className="widget">
							<img className="mb-4" src="./img/icon/Mujaz_Logo_01.png" srcSet="./img/icon/Mujaz_Logo_01.png 2x" alt="" width='250' />
							<p className="lead mb-0">منصّة إرسال بريد إلكتروني جماعي، تساعدك في إنشاء وإرسال محتواك في أسرع وأسهل طريقة وباللغة العربية</p>
						</div>

					</div>
					<div className="col-md-3 col-xs-1">
						<div className="widget">
							<div className="d-flex flex-row">
								<div>
									<div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-location-pin-alt"></i> </div>
								</div>
								<div className="align-self-start justify-content-start" style={{ marginRight: '5px' }}>
									<h5 className="mb-0">العنوان</h5>
									<p className='mb-0'>إسطنبول، تركيا</p>
								</div>
							</div>

						</div>

					</div>
					<div className="col-md-3 col-xs-1">
						<div className="widget">
							<div className="d-flex flex-row">
								<div>
									<div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-phone-volume"></i> </div>
								</div>
								<div style={{ marginRight: '5px' }}>
									<h5 className="mb-1">التلفون</h5>
									<p className="mb-0" style={{ direction: 'ltr' }}>+9 0 535 593 8150</p>
								</div>
							</div>

						</div>

					</div>
					<div className="col-md-3 col-xs-1">
						<div className="widget">
							<div className="d-flex flex-row">
								<div>
									<div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-envelope"></i> </div>
								</div>
								<div style={{ marginRight: '5px' }}>
									<h5 className="mb-1">البريد الإلكتروني</h5>
									<p className="mb-0"><a href="mailto:somar@mujaz.io" className="link-body">hello@mujaz.io</a></p>
								</div>
							</div>

						</div>
					</div>
				</div>

				<hr className="mt-13 mt-md-14 mb-7" />
				<div className="d-md-flex align-items-center justify-content-between">
					<p className="mb-2 mb-lg-0">© 2023 موجز. جميع الحقوق محفوظة.</p>
					<nav className="nav social social-muted mb-0 text-md-end">
						<a href="/terms-policies#terms-conditions" className="link-body" style={{fontSize: '0.9rem'}}> شروط الاستخدام </a>-&nbsp;&nbsp;
						<a href="/terms-policies#privacy-policy" className="link-body" style={{fontSize: '0.9rem'}}>سياسة الخصوصية</a>-&nbsp;&nbsp;
						<a href="/terms-policies#return-policy" className="link-body" style={{fontSize: '0.9rem'}}> سياسة الاسترجاع  </a>
					</nav>
					<nav className="nav social social-muted mb-0 text-md-end">
						<a href="https://x.com/mujaz_io"><i className="uil uil-twitter"></i></a>
						<a href="https://www.facebook.com/mujazplatform?mibextid=ZbWKwL"><i className="uil uil-facebook-f"></i></a>
						<a href="https://www.instagram.com/mujaz.io/"><i className="uil uil-instagram"></i></a>
						<a href="https://www.linkedin.com/company/mujaz/"><i className="uil uil-linkedin"></i></a>
					</nav>
				</div>
			</div>
			<div className="progress-wrap">
				<svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
					<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
				</svg>
			</div>
		</footer>
	);
};

export default FooterNavBar;
