import React, { useEffect, useState } from 'react';
import {
    TextField,
    Grid,
    Snackbar,
    Box,
    Paper,
    Button,
    Typography,
    IconButton
} from '@mui/material';
import { Delete } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "tss-react/mui";
import { LoadingButton } from '@mui/lab';
import { getFirestore, documentId, addDoc, setDoc, deleteDoc, getDocs, getDoc, doc, query, where, collection } from 'firebase/firestore';
import Post from '../../atoms/Post';
import { ArrowForwardIosTwoTone } from '@mui/icons-material';
import { queryAllByDisplayValue } from '@testing-library/react';
import { connect } from 'react-redux';
//import DatePicker from "react-datepicker";
//import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
//import SortIcon from '@mui/icons-material/Sort';
import { confirm } from "react-confirm-box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';

//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { LocalizationProvider } from '@mui/x-date-pickers-pro';
//import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
//import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
//import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
//import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import dayjs from 'dayjs';

import DatePicker from "react-multi-date-picker"
//import { Calendar } from "react-multi-date-picker"
//import DateObject from "react-date-object";
import InputIcon from "react-multi-date-picker/components/input_icon"
import styled from 'styled-components';
import transition from "react-element-popper/animations/transition";
import trash from '../../img/icons/trash.svg'

const NewslettersArchive = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const db = getFirestore();
    const history = useHistory();
    const useStyles = makeStyles()(
        () => ({
            container: {
                backgroundColor: '#FFF',
                marginTop: 40,
                paddingTop: 45,
                paddingBottom: 32
            },
            heading: {
                fontSize: '2em',
                fontWeight: 'bold',
                color: '#F77C15'
            },
            seeMore: {
                cursor: 'pointer',
                fontSize: 30,
                marginTop: 45
            },
            postContainer: {
                maxWidth: 1200,
                '@media only screen and (max-width: 600px)': {
                    display: 'flex',
                    justifyContent: 'center'
                },
                '@media only screen and (min-width: 1200px)': {
                    padding: '0 80px'
                },
            },
            paper: {
                width: 250,
                height: 250
            },
            cardBtns: {
                boxSizing: 'border-box',
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginTop: '15px',
            },
            editBtn: {
                background: '#f63',
                color: '#FFF',
                width: '120px',
                borderRadius: '30px',
                boxShadow: '0px 3px 6px #00000026',
            },
            deleteBtn: {
                width: '120px',
                borderRadius: '30px',
                background: '#FFF',
                color: '#f63',
                boxShadow: '0px 3px 6px #00000026',
            },
            menuSubmitBtn: {
                background: '#fd7422',
                color: '#FFF',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }

            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '50px 0px 0px 0px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '0px 3px 6px #00000026',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },
            datePicker: {
                '&>.react-datepicker-wrapper input': {
                    border: '1px solid !important'
                }
            }
        })
    );
    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container className={classes.confirmContainer}>
                    <Grid item className={classes.confirmText}>
                        <Typography variant="h5">{message}</Typography>
                    </Grid>
                    <Grid item className={classes.confirmAction}>
                        <Button variant="contained" className={classes.confirmBtnConfirm} onClick={onCancel}> تراجع </Button>
                        <Button variant="contained" className={classes.confirmBtnCancel} onClick={onConfirm}> تأكيد </Button>
                    </Grid>



                </Grid>
            );
        }
    };
    let from_date = new Date();
    const { classes } = useStyles();
    const [selectedNewsletter, setSelectedNewsletter] = useState([]);
    const [fromDate, setFromDate] = useState(new Date(from_date.setDate(from_date.getDate() - 7)));
    const [toDate, setToDate] = useState(new Date());
    const { company, data } = props;
    const [isClickedMonth, setIsClickedMonth] = useState(false);
    const [isClickedWeek, setIsClickedWeek] = useState(true);
    const [isClickedDay, setIsClickedDay] = useState(false);
    const [value, setValue] = useState([null, null]);
    const [dateRange, setDateRange] = useState([dayjs().subtract(7, 'day'), dayjs()]);


    function _formatDate(dateIn, symbol) {
        let yyyy = dateIn.getFullYear();
        let mm = dateIn.getMonth() + 1;
        if (symbol != '-') {
            mm = mm.toString().padStart(2, '0');
        }
        let dd = dateIn.getDate();
        return String(dd + symbol + mm + symbol + yyyy);
    }
    const goToNewsletterDetails = async (id) => {
        history.push(`/newsletter/${id}`);
    }
    const fetchNewsletter = async () => {
        let dates = [];
        setSelectedNewsletter([]);
        let Difference_In_Time = toDate.getTime() - fromDate.getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        {
            [...Array(Math.round(Difference_In_Days + 1))].map((item, i) => {
                let date = new Date();
                date.setDate(toDate.getDate() - i);
                date.setMonth(toDate.getMonth());
                date = _formatDate(date, '-');
                dates.push(company + "_" + date);

            })
            if (Difference_In_Days == 0) {
                let date = new Date();
                date.setDate(toDate.getDate());
                date = _formatDate(date, '-');
                dates.push(company + "_" + date);
            }
        }
        console.log("From Date : " + fromDate);
        const q = query(collection(db, "NewslettersArchive"), where(documentId(), "in", dates));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.data());
            if (doc.data()) {
                let hide = doc.data().hide;
                let selected_title = doc.data().subject;
                setSelectedNewsletter(selectedNewsletter => [...selectedNewsletter, {
                    date: doc.id,
                    id: doc.id,
                    title: selected_title,
                    hide: hide
                }])
            }
        });

    }

    const hideNewsletter = async (id, i) => {
        const q = query(collection(db, "NewslettersArchive"), where(documentId(), "==", id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((value, index) => {
            setDoc(doc(db, 'NewslettersArchive', id), {
                hide: true,
                subject: value.data().subject,
                text: value.data().text,
                items: value.data().items,
                html: value.data().html
            }).then(() => {
                setSelectedNewsletter(selectedNewsletter.map((item, _i) => {
                    if (i === _i) {
                        return { ...item, hide: true };
                    }
                    return item;
                }))
            });
            setSnackBar({
                isOpen: true,
                message: 'تم إخفاء النشرة بنجاح',
            });
        })
    }
    const showNewsletter = async (id, i) => {
        const q = query(collection(db, "NewslettersArchive"), where(documentId(), "==", id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((value, index) => {
            setDoc(doc(db, 'NewslettersArchive', id), {
                hide: false,
                subject: value.data().subject,
                text: value.data().text,
                items: value.data().items,
                html: value.data().html
            }).then(() => {
                setSelectedNewsletter(selectedNewsletter.map((item, _i) => {
                    if (i === _i) {
                        return { ...item, hide: false };
                    }
                    return item;
                }))
                setSnackBar({
                    isOpen: true,
                    message: 'تم إظهار النشرة بنجاح',
                });
            });

        })
    }

    const deleteNewsletter = async (id) => {
        const result = await confirm("هل حقا تريد حذف هذه النشرة؟", options);
        if (result) {
            await deleteDoc(doc(db, "NewslettersArchive", id)).then(() => {
                console.log('deleted: ' + id);
                setSelectedNewsletter(selectedNewsletter.filter(item => item.id !== id));
                setSnackBar({
                    isOpen: true,
                    message: 'تم مسح النشرة بنجاح',
                });
            });
        }
    }
    useEffect(() => {
        fetchNewsletter();
    }, []);

    const handleClickMonthButton = () => {
        setIsClickedMonth((isClickedMonth) => !isClickedMonth);
        setIsClickedDay(false);
        setIsClickedWeek(false);

        setFromDate(new Date(from_date.setDate(from_date.getDate() - 30)))
        setToDate(new Date())
    }

    const handleClickDayButton = () => {
        setIsClickedDay((isClickedDay) => !isClickedDay);
        setIsClickedMonth(false);
        setIsClickedWeek(false);

        setFromDate(new Date(from_date.setDate(from_date.getDate() - 1)))
        setToDate(new Date())


    }

    const handleClickWeekButton = () => {
        setIsClickedWeek((isClickedWeek) => !isClickedWeek);
        setIsClickedMonth(false);
        setIsClickedDay(false);

        setFromDate(new Date(from_date.setDate(from_date.getDate() - 7)))
        setToDate(new Date())
    }
    // const WrappedSingleInputDateRangeField = React.forwardRef((props, ref) => {
    //     return <SingleInputDateRangeField size="small" {...props} ref={ref} />;
    // });

    const handleDateChange = (newValue) => {
        console.log("newValue is :" + newValue);
        console.log("dateRange is :" + dateRange);
        setDateRange(newValue)
        if (newValue && newValue[0] && newValue[1]) {
            const start = newValue[0].toDate(); // Convert dayjs object to Date
            const end = newValue[1].toDate();

            console.log("Start Date: ", start);
            console.log("End Date: ", end);

            setFromDate(start);
            setToDate(end);
        }
    };
    // WrappedSingleInputDateRangeField.fieldType = 'single-input';

    const DatePickerWrapper = styled.div`
        position: relative;
        width: 300px;
        
        .rmdp-input {
        padding-top: 8px;
        text-align: center;
        width: 300px;
        box-sizing: border-box;
        height: 40px;
        }

        .icon-tabler-calendar-event {
            padding-left: 5px;
            width: 30px;
            height: 30px;
        }
        
    `;


    return (
        <>
            <Grid container mt={4} sx={{ justifyContent: 'center', alignItems: 'center' }} columns={{ lg: 12 }}>

                <Grid item sm={8} md={10} lg={10}>
                    <Paper elevation={0} >
                        <Box
                            sx={{
                                display: 'flex',
                                // justifyContent: 'center',
                                // alignItems: 'center',
                            }}
                        >
                            <Grid conatainer sx={{ width: '100%', ml: 6, mr: 6, mt: 3, mb: 3, lg: 12 }} direction='column' justifyContent="center" alignItems="center">
                                <Grid item lg={8}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}> النشرات السابقة</Typography>
                                    <Typography variant="body2" sx={{ fontSize: '12px' }}> قم بإداره او مشاهدة النشرة السابقة</Typography>
                                </Grid>

                                <Grid item mb={2} mt={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateRangePicker
                                    value={dateRange}
                                    onChange={
                                        handleDateChange
                                    }
                                    slots={{ field: WrappedSingleInputDateRangeField }}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            InputProps: {
                                                endAdornment: <TodayOutlinedIcon style={{ fontSize: '25' }} />
                                            },
                                            sx: {
                                                width: '300px',
                                                '& .MuiInputBase-root': { // Replace with your actual class name
                                                    height: '40px',
                                                    borderRadius: '7px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                },
                                                '& .MuiInputBase-input': {
                                                    textAlign: 'center',
                                                    alignSelf: 'baseline',
                                                },
                                            }
                                        }
                                    }}

                                />
                            </LocalizationProvider> */}

                                    <DatePickerWrapper>
                                        <DatePicker
                                            value={dateRange}
                                            onChange={handleDateChange}
                                            range
                                            rangeHover
                                            placeholder={"dd/mm/yyyy - dd/mm/yyyy"}
                                            animations={[
                                                transition({ duration: 800, from: 35 })
                                            ]}
                                            render={
                                                <InputIcon />
                                            }
                                        />
                                    </DatePickerWrapper>
                                    <Button variant="contained"
                                        style={{
                                            backgroundColor: isClickedMonth ? '#FF6150' : '#FFFFFF',
                                            color: isClickedMonth ? '#FFFFFF' : '#000000',
                                            border: !isClickedMonth ? '1px solid' : '',
                                            borderRadius: '7px',
                                            height: '40px',
                                            marginLeft: '2px',
                                            marginRight: '10px',
                                        }}
                                        onClick={handleClickMonthButton}
                                    >
                                        شهر
                                    </Button>
                                    <Button variant="contained"
                                        style={{
                                            backgroundColor: isClickedWeek ? '#FF6150' : '#FFFFFF',
                                            color: isClickedWeek ? '#FFFFFF' : '#000000',
                                            border: !isClickedWeek ? '1px solid' : '',
                                            borderRadius: '7px',
                                            height: '40px',
                                            marginLeft: '2px',
                                        }}
                                        onClick={handleClickWeekButton}
                                    >
                                        اسبوع
                                    </Button>
                                    <Button variant="contained"
                                        style={{
                                            backgroundColor: isClickedDay ? '#FF6150' : '#FFFFFF',
                                            color: isClickedDay ? '#FFFFFF' : '#000000',
                                            border: !isClickedDay ? '1px solid' : '',
                                            borderRadius: '7px',
                                            height: '40px',
                                        }}
                                        onClick={handleClickDayButton}
                                    >
                                        يوم
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#FF6150', borderRadius: '7px', marginRight: '10px', height: '40px', }}
                                        onClick={() => fetchNewsletter()}
                                    >
                                        عرض
                                    </Button>
                                </Grid>
                                <TableContainer component={Paper} style={{ boxShadow: 'none', }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead sx={{
                                            background: '#F5F6FA',
                                            borderRadius: '10px',
                                            overflow: 'hidden'
                                        }} >
                                            <TableRow sx={{
                                                background: '#F5F6FA',
                                                '&:first-of-type': {
                                                    borderTopLeftRadius: '10px',
                                                    borderTopRightRadius: '10px',
                                                },
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}>
                                                <TableCell sx={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', borderBottom: "none", flex: 1, }} align='left'>
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        النشرة
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: "none", flex: 1, }} align='center'>
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        التاريخ
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderBottom: "none", flex: 1, }} align='right'>
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        إجراءات
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedNewsletter?.map((row, index) => {
                                                return (
                                                    <>
                                                        <TableRow
                                                            key={index}
                                                            index={index}
                                                            sx={{
                                                                '&:last-child td, &:last-child th': { border: 0 }
                                                                , display: 'flex',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="left" >
                                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                    {row.title}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center" >
                                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                    {row.date.replaceAll(company + "_", '')}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: "none", flex: 1, }} align="right" >
                                                                <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => goToNewsletterDetails(row.id)}>
                                                                    <VisibilityIcon style={{ fontSize: '1rem' }} />
                                                                </IconButton>
                                                                <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => deleteNewsletter(row.id)}>
                                                                    <img src={trash} alt="Trash Logo" style={{ width: '18px', height: '18px', }} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>

                                                )
                                            }
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} mb={4} columns={{ xs: 1, sm: 1, md: 12 }} style={{ justifyContent: 'flex-end' }}>
                <Grid item xs={1} sm={1} md={12}>
                    <Paper elevation={0} >

                        <Grid container spacing={0} style={{ flexDirection: 'row-reverse', paddingLeft: '50px' }}>

                            {/* <Grid item >
                                <Grid container >
                                    <Grid item >
                                        <p style={{ textAlign: 'left', paddingLeft: '5px', marginBottom: 0 }}>من</p>
                                    </Grid>
                                    <Grid item >
                                        <DatePicker
                                            className={classes.datePicker}
                                            placeholderText="من"
                                            id="from"
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(date)}
                                            selectsStart
                                            startDate={fromDate}
                                            endDate={toDate}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item >
                                <Grid container >
                                    <Grid item >
                                        <p style={{ textAlign: 'left', paddingLeft: '5px', marginBottom: 0 }}>إلى</p>
                                    </Grid>
                                    <Grid item >
                                        <DatePicker
                                            id="to"
                                            selected={toDate}
                                            onChange={(date) => setToDate(date)}
                                            selectsEnd
                                            startDate={fromDate}
                                            endDate={toDate}
                                            minDate={fromDate}
                                            maxDate={fromDate.getTime() + (9 * 24 * 60 * 60 * 1000)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            {/* <Grid item >
                                <CalendarMonthIcon />
                            </Grid> */}
                        </Grid>

                        {/* <TableContainer component={Paper} style={{ boxShadow: 'none', padding: '10px 50px 0px 50px' }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{ background: '#F5F6FA', borderRadius: '10px', overflow: 'hidden' }} >
                                    <TableRow sx={{
                                        background: '#F5F6FA',
                                        '&:first-of-type': {
                                            borderTopLeftRadius: '10px',
                                            borderTopRightRadius: '10px',
                                        }
                                    }}>
                                        <TableCell sx={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', borderBottom: "none" }}>
                                            <Typography xs={3} variant="p" >
                                                النشرة
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "none" }}>
                                            <Typography xs={3} variant="p" >
                                                التاريخ
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right" sx={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderBottom: "none" }}>
                                            <Typography xs={3} variant="p" >
                                                إجراءات
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedNewsletter?.map((row, index) => {
                                        return (
                                            <>
                                                <TableRow
                                                    key={index}
                                                    index={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                                                        {row.title}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                                                        {row.date.replaceAll(company + "_", '')}
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ borderBottom: "none" }}>
                                                        <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => goToNewsletterDetails(row.id)}>
                                                            <VisibilityIcon fontSize='small' />
                                                        </IconButton>
                                                        <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => deleteNewsletter(row.id)}>
                                                            <Delete fontSize='small' />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </>

                                        )
                                    }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                    </Paper>
                </Grid>

            </Grid>

            {/* <Grid container mt={2} spacing={2} sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between'
            }}> */}

            {/* </Grid> */}
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>

    );
}
const mapStateToProps = (state) => {
    console.log(state);
    return state
}
export default connect(mapStateToProps)(NewslettersArchive);
