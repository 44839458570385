import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { getFirestore, addDoc, getDocs, query, where, collection, deleteDoc, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as Logo } from "../../img/svgLogo.svg";
import { ReactComponent as SmallLogo } from "../../img/logo.svg";
import { LoadingButton } from '@mui/lab';
import EmailIcon from '@mui/icons-material/Email';
import moment from 'moment/moment';

const Unsubscribe = () => {
  const { company, email } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const history = useHistory();
  const [value, setValue] = useState({
    email: email,
    company: company,
    reason: '',
    additionalInfo: '',
    Date: moment(new Date()).format('YYYY-MM-DD')
  });
  const [status, setStatus] = useState({ status: 'pending', message: '' });

  const labels = [
    'لم أعد أرغب في تلقي هذه الرسائل الإلكترونية',
    'لم أوافق على تلقي هذه الرسائل الإلكترونية',
    'هذه الرسائل الإلكترونية ليست ذات صلة أو مثيرة للاهتمام',
    'أتلقى رسائل البريد الإلكتروني هذه بشكل متكرر',
    'تحتوي هذه الرسالة الإلكترونية على محتوى مخادع و / أو مضلل'];

  const db = getFirestore();

  const scrollPage = (id, mb) => {
    if (id) {
      history.push(`/${id}/${mb ? mb : ''}`);
    }
  }

  const useStyles = makeStyles()(
    () => ({
      // container: {
      //   display: 'flex',
      //   flexDirection: 'column',
      //   alignItems: 'stretch',
      //   // justifyContent: 'center',
      //   height: '100vh',
      //   color: '#FD7422'
      // },
      // navContainer: {
      //   width: '100%',
      //   display: 'flex',
      //   justifyContent: 'center'
      // },
      // contentWrapper: {
      //   display: 'flex',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   width: '100%',
      //   height: 'calc(100vh - 240px)',
      //   flexGrow: 1,
      //   marginBottom: -40
      // },
      topLogo: {
        // width: '50%',
        cursor: 'pointer'
      },
      // content: {
      //   width: '60%',
      //   // marginBottom: '-40px',
      //   display: 'flex',
      //   flexDirection: 'column',
      //   alignItems: 'center',
      //   padding: '0px 20px',
      //   '@media only screen and (max-width: 950px)': {
      //     width: '100%'
      //   },
      //   '& h5': {
      //     textAlign: 'center',
      //     fontWeight: 'bold'
      //   }
      // },
      // textContent: {
      //   marginTop: 20,
      //   marginBottom: 20,
      //   width: '50%',
      //   '@media only screen and (max-width: 650px)': {
      //     width: '80%'
      //   },
      // },
      // email: {
      //   marginTop: 15
      // },
      helperText: {
        fontSize: '0.75em',
        color: '#FD7422',
        marginTop: 8
      },
      // buttonContainer: {
      //   display: 'flex',
      //   justifyContent: 'space-around',
      //   '& button': {
      //     borderRadius: 50,
      //     width: 125,
      //   },
      //   marginBottom: 20,
      //   '@media only screen and (max-width: 300px)': {
      //     '& button': {
      //       width: 90,
      //     },
      //   },
      // },
      // cancelButton: {
      //   background: '#FCFCFC',
      //   color: '#FD7422',
      //   marginRight: 60
      // },
      // logo: {
      //   display: 'flex',
      //   justifyContent: 'center',
      //   '& svg': {
      //     width: 50
      //   }
      // },
      // contactInfo: {
      //   marginTop: 30,
      //   '& span': {
      //     display: 'block',
      //     textAlign: 'center'
      //   }
      // },
      // footer: {
      //   flexShrink: 0
      // },
      // typography: {
      //   fontSize: '1rem',
      //   '@media only screen and (max-width: 450px)': {
      //     fontSize: '1rem'
      //   },
      // }
      paddingFields: {
        padding: '5px 0',
        fontSize: '0.9rem'
      }
    })
  );

  const { classes } = useStyles();

  const recordUnsubscribe = async () => {
    await addDoc(collection(db, 'Unsubscribers'), {
      company: value.company,
      email: value.email,
      reason: value.reason,
      additionalInfo: value.additionalInfo,
      Date: serverTimestamp()
    })
  }

  const handleUnsubscribe = async () => {
    if (value.reason == '') {
      setStatus({
        status: 'error',
        message: 'الرجاء إختيار سبب إلغاء الإشاراك من فضلك'
      });
    } else {
      setStatus({
        status: 'pending',
        message: ''
      });
      setIsLoading(true);
      let q = query(collection(db, 'Subscribers'), where('company', '==', value.company), where('email', '==', value.email));
      let searchForEmail = await getDocs(q);
      if (searchForEmail.size != 0) {
        recordUnsubscribe();
        let docId = searchForEmail.docs[0].id;
        const docRef = doc(db, "Subscribers", docId);
        const res = await deleteDoc(docRef);
        setUnsubscribed(true);
        setIsLoading(false);
      } else {
        setStatus({
          status: 'error',
          message: 'عذرًا، البريد الإلكتروني الذي أدخلته غير مسجل'
        });
        setIsLoading(false);
      }
    }
  }

  const handleChange = (a, i) => {
    setValue({
      ...value,
      reason: i
    })

  }
  useEffect(() => {
  }, [value]);

  return (
    <Grid container spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh', padding: '32px' }}>
      <Card variant="outlined" style={{ padding: 10 }}>
        {!unsubscribed ? (
          <Grid container spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '80vh', padding: '32px' }}>
            <Grid item>
              <CardContent>
                <FormControl>
                  <Typography className={classes.helperText}>
                    {status.message}
                  </Typography>
                  <Typography variant="h6" component="div" mb={1}>
                    قم بإلغاء إشتراكي من كل الرسائل البريدية
                  </Typography>
                  <FormLabel id="demo-controlled-radio-buttons-group">إذا كان لديك دقيقة ، فالرجاء إخبارنا بسبب إلغاء اشتراكك:</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    // value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel value={labels[0]} className={classes.paddingFields} control={<Radio size="small" />} label={<Typography variant="p">{labels[0]}</Typography>} />
                    <FormControlLabel value={labels[1]} className={classes.paddingFields} control={<Radio size="small" />} label={<Typography variant="p">{labels[1]}</Typography>} />
                    <FormControlLabel value={labels[2]} className={classes.paddingFields} control={<Radio size="small" />} label={<Typography variant="p">{labels[2]}</Typography>} />
                    <FormControlLabel value={labels[3]} className={classes.paddingFields} control={<Radio size="small" />} label={<Typography variant="p">{labels[3]}</Typography>} />
                    <FormControlLabel value={labels[4]} className={classes.paddingFields} control={<Radio size="small" />} label={<Typography variant="p">{labels[4]}</Typography>} />
                  </RadioGroup>
                  <TextField
                    label="اختياريا تقديم مزيد من المعلومات"
                    multiline rows={3} variant="outlined"
                    className={classes.paddingFields}
                    onChange={(event) => {
                      setValue({
                        ...value,
                        additionalInfo: event.target.value
                      })
                    }} />
                </FormControl>
              </CardContent>
            </Grid>
            <Grid item>
              <LoadingButton loading={isLoading} variant="contained" onClick={handleUnsubscribe} >
                إلغاء الإشتراك
              </LoadingButton>
            </Grid>
          </Grid>
        ) : (
          <>
            <Logo className={classes.topLogo} onClick={() => history.push('/')} />
            <Typography className={classes.typography} variant='h5'>تم إلغاء اشتراكك بنجاح.</Typography>
            <div className={classes.contactInfo}>
              <Typography variant='h7'>لمزيد من المعلومات</Typography>
              <Typography variant='h7'>hello@mujaz.io</Typography>
            </div>
          </>
        )}
      </Card>
    </Grid>


    // <div className={classes.container}>
    //   <div className={classes.contentWrapper}>
    //     <div className={classes.content}>
    //       <Logo className={classes.topLogo} onClick={() => history.push('/')} />
    //       {!unsubscribed ? (
    //       <>
    //       <div className={classes.textContent}>
    //         <Typography className={classes.typography} variant='h5' >نأسف سماع أنه  سوف يتم إلغاء اشتراكك  لن تصلك نشرات موجز بعد الآن</Typography>
    //         {/* <Typography variant='h5' className={classes.email}>{}</Typography> */}
    //         <TextField
    //           className={classes.email}
    //           fullWidth
    // 					onChange={({ target: { value } }) => setEmail(value)}
    // 					value={email}
    //           autoComplete="email"
    //           label="البريد الالكتروني"
    //           InputProps={{
    //             startAdornment: (
    //               <InputAdornment position="start">
    //                 <EmailIcon />
    //               </InputAdornment>
    //             )
    //           }}
    //         />
    //         <Typography className={classes.helperText}>
    // 										{status.message}
    // 				</Typography>

    //       </div>
    //       <div className={classes.buttonContainer}> 
    //         <LoadingButton loading={isLoading} variant="contained" onClick={handleUnsubscribe} >
    //            تأكيد 
    //         </LoadingButton>
    //         <LoadingButton variant="contained"  className={classes.cancelButton} onClick={() => history.push('/')}>
    //            تراجع       
    //         </LoadingButton>
    //       </div>
    //       </>
    //       ) : (
    //         <>
    //         <Typography className={classes.typography} variant='h5'>تم إلغاء اشتراكك بنجاح.</Typography>
    //         <div className={classes.contactInfo}>
    //           <Typography variant='h7'>لمزيد من المعلومات</Typography>
    //           <Typography variant='h7'>hello@mujaz.io</Typography>
    //         </div>
    //         </>
    //       )}
    //       <div className={classes.logo}>
    //         <SmallLogo />
    //       </div>
    //     </div>
    //   </div>
    //   <div className={classes.footer}>
    //   < Footer  />
    //   </div>
    // </div>
  );
};

export default Unsubscribe;
