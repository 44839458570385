import React, { useState } from 'react';
import {
	Grid,
	Button,
  SvgIcon,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { getFirestore, addDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { ReactComponent as Facebook } from '../img/facebook.svg';
import { ReactComponent as Instagram } from '../img/instagram.svg';
import { ReactComponent as Twitter } from '../img/twitter.svg';


const SocialHolder = ({fill,className}) => {

	const db = getFirestore();

	const useStyles = makeStyles({fill})(
    () => ({
				socialHolder: {
					display:"flex",
					justifyContent: 'flex-end',
          alignItems: 'center',
          // width: 30,
					'&>svg':{
            '&:first-of-type':{
              marginRight: 0
						}
					}
				},
        icon: {
          marginRight: 33,
          width: 25,
          cursor: 'pointer',
          '& path':{
            fill: fill ? fill : '#F77C15'
          }
        }
    })
);

const { classes } = useStyles({fill});

const handleClick = (pageLink) => {
  window.open(pageLink, "_blank")
}

	return (
				<div xs={2} className={`${classes.socialHolder} ${className ? className : ''}`}>
            <Facebook onClick={()=> handleClick('https://www.facebook.com/mujaz.io')} className={classes.icon} />
            <Twitter onClick={()=> handleClick('https://twitter.com/mujaz_io')} className={classes.icon} />
            <Instagram onClick={()=> handleClick('https://www.instagram.com/mujaz.io/')}  className={classes.icon} />
				</div>
	);
};

export default SocialHolder;
