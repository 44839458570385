import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { generateIntroCard, generateWrappedEmail, generateFooter } from '../utils';

const HTMLViewer = ({ intro, items, weather }) => {
	const [htmlView, setHtmlView] = useState('');
	
	const generateHtmlView = () => {
		console.log(weather);
		let html = generateIntroCard(intro);
		html = html + items.reduce((prev, curr) => prev + curr.html, '');
		html = generateWrappedEmail(html, '', intro.substr(0, 100) + '...');
		// Send empty subject for display purposes only.
		setHtmlView(html);
	};

	useEffect(() => {
		generateHtmlView();
	}, [intro, weather]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Button onClick={generateHtmlView}>تحديث المحتوى</Button>
			</Grid>
			<Grid item xs={12}>
				<div dangerouslySetInnerHTML={{ __html: htmlView }} />
			</Grid>
		</Grid>
	);
};

HTMLViewer.propTypes = {
	intro: PropTypes.string,
	items: PropTypes.array,
};

export default HTMLViewer;
