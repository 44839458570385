import { Snackbar, Grid, Stack, TextField, Button, Autocomplete, Paper, Typography, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ConfirmWeatherCard from '../../components/newsCards/ConfirmWeatherCard';
import ConfirmStocksCard from '../../components/newsCards/ConfirmStocksCard';
import ConfirmCurrenciesCard from '../../components/newsCards/ConfirmCurrenciesCard';
import ConfirmImageCard from '../../components/newsCards/ConfirmImageCard';
import ConfirmNewsWithImage from '../../components/newsCards/ConfirmNewsWithImage';
import { makeStyles } from "tss-react/mui";
import { doc, addDoc, documentId, getDoc, collection, getFirestore, getDocs, setDoc, query, where } from 'firebase/firestore';
import { confirm } from "react-confirm-box";
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import trash from '../../img/icons/trash.svg'


const NewsLibrary = (props) => {
    const [category, setCategory] = useState('');
    const [section, setSection] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const [image, setImage] = useState('');
    const [credits, setCredits] = useState('');
    const [alt, setAlt] = useState('');
    const [catOptions, setCatOptions] = useState([]);
    const [editCard, setEditCard] = useState({});
    const [newsLibraryVisible, setNewsLibraryVisible] = useState(true);
    const [weatherConfirmVisible, setWeatherConfirmVisible] = useState(false);
    const [stocksConfirmVisible, setStocksConfirmVisible] = useState(false);
    const [currenciesConfirmVisible, setCurrenciesConfirmVisible] = useState(false);
    const [imageConfirmVisible, setImageConfirmVisible] = useState(false);
    const [newsWithImageConfirmVisible, setNewsWithImageConfirmVisible] = useState(false);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });

    const db = getFirestore();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                width: 250,
                height: 250
            },
            cardBtns: {
                boxSizing: 'border-box',
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-end',
            },
            editBtn: {
                background: '#f63',
                color: '#FFF',
                width: '100px',
                boxShadow: '0px 3px 6px #00000026',
                borderRadius: '30px',
            },
            deleteBtn: {
                width: '100px',
                borderRadius: '30px',
                boxShadow: '0px 3px 6px #00000026',
                marginLeft: '20px',
                background: '#FFF',
                color: '#f63',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }
            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '50px 0px 0px 0px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '0px 3px 6px #00000026',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },
            confirmBtnConfirm: {

            },
            confirmBtnCancel: {
                // color: '#ff6633',
            }

        })
    );

    const { classes } = useStyles();
    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container className={classes.confirmContainer}>
                    <Grid item className={classes.confirmText}>
                        <Typography variant="h5">{message}</Typography>
                    </Grid>
                    <Grid item className={classes.confirmAction}>
                        <Button variant="contained" className={classes.confirmBtnConfirm} onClick={onCancel}> تراجع </Button>
                        <Button variant="contained" className={classes.confirmBtnCancel} onClick={onConfirm}> تأكيد </Button>
                    </Grid>



                </Grid>
            );
        }
    };
    const getDateId = (_company) => {
        return `${_company}_${parseInt(new Date().getDate()) + (new Date().getHours() < 5 ? 0 : 1)}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`;
    };
    const saveNewsWithImage = async () => {
        console.log(body);

        const docTitle = getDateId();

        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: {
                    category: category,
                    section: section,
                    title: title,
                    image: image,
                    alt: alt,
                    credits: credits,
                    body: body
                },
                Forex: data.data().Forex,
                Weather: data.data().Weather,
                Image: data.data().Image,
                Currency: data.data().Currency
            }).then((data) => {
                console.log('done');
            });
        }
    }

    const getCategories = async () => {
        const categoriesRef = collection(db, "Categories");
        const q = query(categoriesRef, where("company", "==", "mujaz"));
        let data = await getDocs(q);
        data.forEach((doc) => {
            console.log(doc.data());
            // setData(data => [...data, { 
            //     Currency: doc.data().name,
            //     Forex: doc.data().name,
            //     Image: doc.data().name,
            //     News: doc.data().name,
            //     Weather: doc.data().name,
            // }]);
        });
    }
    const dateFormat = (inputDate, format) => {
        //parse the input date
        const date = new Date(inputDate);
        console.log(date);
        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        //replace the month
        format = format.replace("MM", month.toString().padStart(2, "0"));

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2, 2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2, ""));

        return format;
    }
    const getData = async () => {
        setItems([]);
        const dataRef = collection(db, "NewsLibrary");
        const q = query(dataRef, where('data.company', "==", company));
        let data = await getDocs(q);
        data.forEach((doc) => {
            setItems(items => [...items, {
                Currency: doc.data().Currency,
                Stocks: doc.data().Stocks,
                Image: doc.data().Image,
                News: doc.data().News,
                Weather: doc.data().Weather,
                data: doc.data().data
            }]);
        });
    }

    const handleEditCard = (cardData, type) => {
        setEditCard(cardData);  //push card data to state
        setNewsLibraryVisible(false);
        setWeatherConfirmVisible(false);
        setStocksConfirmVisible(false);
        setCurrenciesConfirmVisible(false);
        setImageConfirmVisible(false);
        setNewsWithImageConfirmVisible(false);
        switch (type) {
            case 'Weather':
                setWeatherConfirmVisible(true);
                break;
            case 'Stocks':
                setStocksConfirmVisible(true);
                break;
            case 'Currency':
                setCurrenciesConfirmVisible(true);
                break;
            case 'Image':
                setImageConfirmVisible(true);
                break;
            case 'News':
                setNewsWithImageConfirmVisible(true);
                break;
        }


        console.log(cardData);

    }

    const handleDeleteNode = async (obj, node) => {
        console.log(obj);
        const result = await confirm("هل حقا تريد حذف هذا الخبر؟", options);
        if (result) {

            const docTitle = company + "_" + obj.date;
            let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
            if (data.data()) {
                let updatedDoc = {
                    News: node === "News" ? data.data().News.filter((res) => res.id != obj.id) : data.data().News,
                    Stocks: node === "Stocks" ? '' : data.data().Stocks,
                    Weather: node === "Weather" ? '' : data.data().Weather,
                    Image: node === "Image" ? data.data().Image.filter((res) => res.id != obj.id) : data.data().Image,
                    Currency: node === "Currency" ? '' : data.data().Currency,
                    data: data.data().data
                }
                await setDoc(doc(db, 'NewsLibrary', docTitle), updatedDoc).then(() => {
                    setItems(values => {
                        return values.map((oldValue, i) => {
                            return oldValue.data.date === obj.date ? updatedDoc : oldValue;
                        })
                    });
                    setSnackBar({
                        isOpen: true,
                        message: 'تم مسح الخبر بنجاح',
                    });
                })
            }
        }
    }

    const handleCancelConfirmCard = () => {
        setNewsLibraryVisible(true);
        setWeatherConfirmVisible(false);
        setStocksConfirmVisible(false);
        setCurrenciesConfirmVisible(false);
        setImageConfirmVisible(false);
        setNewsWithImageConfirmVisible(false);
    }

    const handleEditConfirmWeatherCard = async (weatherItems, body, date) => {
        // const company = 'mujaz';
        const docTitle = company + "_" + date;
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                Currency: data.data().Currency,
                Image: data.data().Image,
                News: data.data().News,
                Stocks: data.data().Stocks,
                Weather: {
                    body: body,
                    category: data.data().Weather.category,
                    date: data.data().Weather.date,
                    id: data.data().Weather.id,
                    items: weatherItems,
                    title: data.data().Weather.title
                },
                data: data.data().data
            }).then(() => {
                console.log('confirmed');
                setItems(values => {
                    return values.map((oldValue, i) => {
                        return oldValue.data.date === date ? {
                            Currency: data.data().Currency,
                            Image: data.data().Image,
                            News: data.data().News,
                            Stocks: data.data().Stocks,
                            Weather: {
                                body: body,
                                category: data.data().Weather.category,
                                date: data.data().Weather.date,
                                id: data.data().Weather.id,
                                items: weatherItems,
                                title: data.data().Weather.title
                            },
                            data: data.data().data
                        } : oldValue;
                    })
                })
                setSnackBar({
                    isOpen: true,
                    message: 'تم تعديل الطقس بنجاح',
                });
            })
        }
    }

    const handleEditConfirmStocksCard = async (stockItems, body, date) => {
        // const company = 'mujaz';
        const docTitle = company + "_" + date;
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                Currency: data.data().Currency,
                Image: data.data().Image,
                News: data.data().News,
                Stocks: {
                    body: body,
                    category: data.data().Stocks.category,
                    date: data.data().Stocks.date,
                    id: data.data().Stocks.id,
                    items: stockItems,
                    title: data.data().Stocks.title
                },
                Weather: data.data().Weather,
                data: data.data().data
            }).then(() => {
                console.log('confirmed');
                setItems(values => {
                    return values.map((oldValue, i) => {
                        return oldValue.data.date === date ? {
                            Currency: data.data().Currency,
                            Image: data.data().Image,
                            News: data.data().News,
                            Stocks: {
                                body: body,
                                category: data.data().Stocks.category,
                                date: data.data().Stocks.date,
                                id: data.data().Stocks.id,
                                items: stockItems,
                                title: data.data().Stocks.title
                            },
                            Weather: data.data().Weather,
                            data: data.data().data
                        } : oldValue;
                    })
                })
                setSnackBar({
                    isOpen: true,
                    message: 'تم تعديل البورصة بنجاح',
                });
            })
        }
    }

    const handleEditConfirmCurrenciesCard = async (currenciesItems, date) => {
        // const company = 'mujaz';
        const docTitle = company + "_" + date;
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                Currency: {
                    category: data.data().Currency.category,
                    date: data.data().Currency.date,
                    id: data.data().Currency.id,
                    items: currenciesItems,
                    title: data.data().Currency.title
                },
                Image: data.data().Image,
                News: data.data().News,
                Stocks: data.data().Stocks,
                Weather: data.data().Weather,
                data: data.data().data
            }).then(() => {
                console.log('confirmed');
                setItems(values => {
                    return values.map((oldValue, i) => {
                        return oldValue.data.date === date ? {
                            Currency: {
                                category: data.data().Currency.category,
                                date: data.data().Currency.date,
                                id: data.data().Currency.id,
                                items: currenciesItems,
                                title: data.data().Currency.title
                            },
                            Image: data.data().Image,
                            News: data.data().News,
                            Stocks: data.data().Stocks,
                            Weather: data.data().Weather,
                            data: data.data().data
                        } : oldValue;
                    })
                })
                setSnackBar({
                    isOpen: true,
                    message: 'تم تعديل العملات بنجاح',
                });
            })
        }
    }

    const handleEditConfirmImageCard = async (alt, credits, image, section, title, defaultValue) => {
        // const company = 'mujaz';
        const docTitle = company + "_" + defaultValue.date;
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            let updatedArr = data.data().Image.map((v, k) => {
                return v.id === defaultValue.id ? {
                    id: defaultValue.id,
                    alt: alt,
                    category: v.category,
                    credits: credits,
                    date: v.date,
                    image: image,
                    section: section,
                    title: title
                } : v
            });
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                Currency: data.data().Currency,
                Image: updatedArr,
                News: data.data().News,
                Stocks: data.data().Stocks,
                Weather: data.data().Weather,
                data: data.data().data
            }).then(() => {
                let obj = {
                    Currency: data.data().Currency,
                    Image: updatedArr,
                    News: data.data().News,
                    Stocks: data.data().Stocks,
                    Weather: data.data().Weather,
                    data: data.data().data
                }
                setItems(values => {
                    return values.map((oldValue, i) => {
                        return oldValue.data.date === defaultValue.date ? {
                            Currency: data.data().Currency,
                            Image: updatedArr,
                            News: data.data().News,
                            Stocks: data.data().Stocks,
                            Weather: data.data().Weather,
                            data: data.data().data
                        } : oldValue;
                    })
                })
                setSnackBar({
                    isOpen: true,
                    message: 'تم تعديل الصورة بنجاح',
                });
            })
        }
    }

    const handleEditConfirmNewsWithImageCard = async (alt, credits, image, section, title, body, category, _newDate, defaultValue) => {
        // const company = 'mujaz';


        let newDate = dateFormat(_newDate, 'dd-MM-yyyy');
        const docTitle = company + "_" + defaultValue.date;
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        console.log(defaultValue.date)
        console.log(newDate);
        if (defaultValue.date != newDate) {
            const newDocTitle = company + "_" + newDate;
            let NewData = await getDoc(doc(db, 'NewsLibrary', newDocTitle));
            if (typeof NewData.data() === "undefined") {
                setDoc(doc(db, 'NewsLibrary', newDocTitle), {
                    News: [],
                    Stocks: '',
                    Weather: '',
                    Image: [],
                    Currency: '',
                    data: {
                        date: newDate,
                        company: company
                    }
                })
                NewData = await getDoc(doc(db, 'NewsLibrary', newDocTitle));
            }
            let updatedArr = data.data().News.map((oldValue, i) => {
                if (oldValue.id != defaultValue.id) {
                    return {
                        id: defaultValue.id,
                        alt: alt,
                        body: body,
                        category: category,
                        credits: credits,
                        date: defaultValue.date,
                        image: image,
                        section: section,
                        title: title
                    }
                }
                return oldValue;
            })
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                Currency: data.data().Currency,
                Image: data.data().Image,
                News: updatedArr,
                Stocks: data.data().Stocks,
                Weather: data.data().Weather,
                data: data.data().data
            }).then(() => {
                if (NewData.data()) {
                    let NewUpdatedArr = NewData.data().News.concat({
                        id: defaultValue.id,
                        alt: alt,
                        body: body,
                        category: category,
                        credits: credits,
                        date: newDate,
                        image: image,
                        section: section,
                        title: title
                    });
                    setDoc(doc(db, 'NewsLibrary', newDocTitle), {
                        Currency: NewData.data().Currency,
                        Image: NewData.data().Image,
                        News: NewUpdatedArr,
                        Stocks: NewData.data().Stocks,
                        Weather: NewData.data().Weather,
                        data: NewData.data().data
                    })
                }
            })

        } else {
            if (data.data()) {
                let updatedArr = data.data().News.map((v, k) => {
                    return v.id === defaultValue.id ? {
                        id: defaultValue.id,
                        alt: alt,
                        body: body,
                        category: category,
                        credits: credits,
                        date: defaultValue.date,
                        image: image,
                        section: section,
                        title: title
                    } : v
                });
                // let updatedArr = data.data().News.map((oldValue, i) => {
                //     return oldValue.date != defaultValue.date ? {
                //         id: defaultValue.id,
                //         alt: alt,
                //         body: body,
                //         category: category,
                //         credits: credits,
                //         date: defaultValue.date,
                //         image: image,
                //         section: section,
                //         title: title
                //     } : oldValue;
                // })
                await setDoc(doc(db, 'NewsLibrary', docTitle), {
                    Currency: data.data().Currency,
                    Image: data.data().Image,
                    News: updatedArr,
                    Stocks: data.data().Stocks,
                    Weather: data.data().Weather,
                    data: data.data().data
                }).then(() => {
                    // setItems(values => {
                    //     return values.map((oldValue, i) => {
                    //         return oldValue.data.date === defaultValue.date ? {
                    //             Currency: data.data().Currency,
                    //             Image: data.data().Image,
                    //             News: updatedArr,
                    //             Stocks: data.data().Stocks,
                    //             Weather: data.data().Weather,
                    //             data: data.data().data
                    //         } : oldValue;
                    //     })
                    // })
                })


            }
        }
        setSnackBar({
            isOpen: true,
            message: 'تم تعديل الخبر بنجاح',
        });
        getData();
    }

    useEffect(() => {
        getData();
    }, []);

    return (

        <>
            <Grid container mt={3} sx={{ justifyContent: 'center' }} columns={{ lg: 12 }}>
                <Grid item lg={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button size={"small"} style={{ backgroundColor: '#FF6150', borderRadius: '7px', }} variant="contained" onClick={() => { }}>
                        عرض ارشيف المحتوى (قريبا)
                    </Button>
                </Grid>
            </Grid>
            <Grid container mt={3.5} mb={4} sx={{ justifyContent: 'center', alignItems: 'center' }} columns={{ lg: 12 }}>
                <Grid item lg={10}>
                    <Paper elevation={0} >
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Grid conatainer mb={4} spacing={2} sx={{ width: '100%', ml: 6, mr: 6, paddingTop: '35px' }} direction='column' justifyContent="center" alignItems="center">

                                <Grid item mb={6}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>مكتبة المحتوى </Typography>
                                    <Typography variant="body2" sx={{ fontSize: '12px' }}>  قم بتحرير وتعديل المحتوى الذي ادخلته</Typography>
                                </Grid>

                                {weatherConfirmVisible && <ConfirmWeatherCard
                                    defaultValue={editCard}
                                    props={props}
                                    handleCancelConfirmCard={handleCancelConfirmCard}
                                    handleEditConfirmWeatherCard={handleEditConfirmWeatherCard} />}

                                {stocksConfirmVisible && <ConfirmStocksCard
                                    defaultValue={editCard}
                                    props={props}
                                    handleCancelConfirmCard={handleCancelConfirmCard}
                                    handleEditConfirmStocksCard={handleEditConfirmStocksCard} />}

                                {currenciesConfirmVisible && <ConfirmCurrenciesCard
                                    defaultValue={editCard}
                                    props={props}
                                    handleCancelConfirmCard={handleCancelConfirmCard}
                                    handleEditConfirmCurrenciesCard={handleEditConfirmCurrenciesCard} />}

                                {imageConfirmVisible && <ConfirmImageCard
                                    defaultValue={editCard}
                                    props={props}
                                    handleCancelConfirmCard={handleCancelConfirmCard}
                                    handleEditConfirmImageCard={handleEditConfirmImageCard} />}

                                {newsWithImageConfirmVisible && <ConfirmNewsWithImage
                                    defaultValue={editCard}
                                    props={props}
                                    handleCancelConfirmCard={handleCancelConfirmCard}
                                    handleEditConfirmNewsWithImageCard={handleEditConfirmNewsWithImageCard} />}



                                {newsLibraryVisible &&
                                    <TableContainer component={Paper} style={{ boxShadow: 'none', }}>
                                        <Table sx={{ minWidth: 500, }} aria-label="simple table">
                                            <TableHead sx={{
                                                background: '#F5F6FA',
                                                borderRadius: '10px',
                                                overflow: 'hidden',

                                            }}>
                                                <TableRow sx={{
                                                    background: '#F5F6FA',
                                                    '&:first-of-type': {
                                                        borderTopLeftRadius: '10px',
                                                        borderTopRightRadius: '10px',
                                                        borderBottomRightRadius: '10px',
                                                        borderBottomLeftRadius: '10px',
                                                    },
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}>
                                                    <TableCell sx={{ borderBottom: "none", flex: 1, }} align='left'>
                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                            العنوان
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                            الفئة
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', flex: 1, }} align="center">
                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                            التاريخ
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: "none", borderBottomLeftRadius: '10px', flex: 1, }} align="right">
                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                            الاجراءات
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items?.map((row, index) => {
                                                    return (
                                                        <React.Fragment
                                                            key={index}>
                                                            {row?.Weather &&
                                                                (<TableRow
                                                                    key={row?.Weather.id}
                                                                    index={index}
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="left">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {row.Weather.date}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {row.Weather.category}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {row.Weather.title}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell sx={{ borderBottom: 'none', flex: 1, }} align="right">
                                                                        {/* <IconButton size='small' color='primary' aria-label="delete" className={classes.btns} onClick={() => handleDeleteNode(row, 'Weather')}>
                                                                            <Delete style={{ fontSize: '1rem' }} />
                                                                        </IconButton>
                                                                        <IconButton size='small' color='primary' aria-label="edit" className={classes.btns} onClick={() => handleEditCard(row.Weather, 'Weather')}>
                                                                            <Edit style={{ fontSize: '1rem' }} />
                                                                        </IconButton> */}

                                                                        <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleEditCard(row.Weather, 'Weather')}>
                                                                            <Edit fontSize='small' />
                                                                        </IconButton>
                                                                        <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteNode(row, 'Weather')}>
                                                                            <img src={trash} alt="Trash Logo" style={{ width: '20px', height: '20px', }} />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>)}
                                                            {row?.Currency &&
                                                                (<TableRow
                                                                    key={row?.Currency.id}
                                                                    index={index}
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="left">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {row.Currency.date}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {row.Currency.category}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {row.Currency.title}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell sx={{ padding: '0px', borderBottom: 'none', flex: 1, }} align="right">
                                                                        {/* <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteNode(row, 'Currency')}>
                                                                            <Delete style={{ fontSize: '1rem' }} />
                                                                        </IconButton>
                                                                        <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleEditCard(row.Currency, 'Currency')}>
                                                                            <Edit style={{ fontSize: '1rem' }} />
                                                                        </IconButton> */}

                                                                        <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleEditCard(row.Curency, 'Currency')}>
                                                                            <Edit fontSize='small' />
                                                                        </IconButton>
                                                                        <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteNode(row, 'Currency')}>
                                                                            <img src={trash} alt="Trash Logo" style={{ width: '20px', height: '20px', }} />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>)}
                                                            {row?.Stocks &&
                                                                (<TableRow
                                                                    key={row?.Stocks.id}
                                                                    index={index}
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="left">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {row.Stocks.date}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {row.Stocks.category}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {row.Stocks.title}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell sx={{ padding: '0px', borderBottom: 'none', flex: 1, }} align="right">
                                                                        {/* <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteNode(row, 'Stocks')}>
                                                                            <Delete style={{ fontSize: '1rem' }} />
                                                                        </IconButton>
                                                                        <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleEditCard(row.Stocks, 'Stocks')}>
                                                                            <Edit style={{ fontSize: '1rem' }} />
                                                                        </IconButton> */}

                                                                        <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleEditCard(row.stocks, 'Stocks')}>
                                                                            <Edit fontSize='small' />
                                                                        </IconButton>
                                                                        <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteNode(row, 'Stocks')}>
                                                                            <img src={trash} alt="Trash Logo" style={{ width: '20px', height: '20px', }} />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>)}
                                                            {row?.News &&
                                                                row.News.map((n, i) =>
                                                                (<TableRow
                                                                    key={n.id}
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="left">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {n.date}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {n.category}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {n.title}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell sx={{ padding: '0px', borderBottom: 'none', flex: 1, }} align="right">
                                                                        {/* <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteNode(n, 'News')}>
                                                                            <Delete style={{ fontSize: '1rem' }} />
                                                                        </IconButton>
                                                                        <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleEditCard(n, 'News')}>
                                                                            <Edit style={{ fontSize: '1rem' }} />
                                                                        </IconButton> */}

                                                                        <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleEditCard(n, 'News')}>
                                                                            <Edit fontSize='small' />
                                                                        </IconButton>
                                                                        <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteNode(n, 'News')}>
                                                                            <img src={trash} alt="Trash Logo" style={{ width: '20px', height: '20px', }} />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>))}
                                                            {row?.Image &&
                                                                row.Image.map((im, i) =>
                                                                (<TableRow
                                                                    key={im.id}
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="left">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {im.date}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {im.category}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                            {im.title}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell sx={{ padding: '0px', borderBottom: 'none', flex: 1, }} align="right">
                                                                        {/* <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleEditCard(im, 'Image')}>
                                                                            <Edit style={{ fontSize: '1rem' }} />
                                                                        </IconButton>
                                                                        <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteNode(im, 'Image')}>
                                                                            <Delete style={{ fontSize: '1rem' }} />
                                                                        </IconButton> */}

                                                                        <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleEditCard(im, 'Image')}>
                                                                            <Edit fontSize='small' />
                                                                        </IconButton>
                                                                        <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteNode(im, 'Image')}>
                                                                            <img src={trash} alt="Trash Logo" style={{ width: '20px', height: '20px', }} />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>))}
                                                        </React.Fragment>
                                                    )
                                                }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>}
                                <Snackbar
                                    open={snackBar.isOpen}
                                    autoHideDuration={6000}
                                    onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                                    message={snackBar.message}
                                />
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </>
    );
};
const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(NewsLibrary);
