import { Stack, TextField, Grid, Button, Typography, Snackbar } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import ImageUploader from '../../components/ImageUploader';
import { generateKey } from '../../utils';
import SideBarMenu from '../../components/SideBarMenu';
import { makeStyles } from "tss-react/mui";
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc } from 'firebase/firestore';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import Preview from '../../components/Preview';
import { connect } from 'react-redux';
import PdfUploader from '../../components/PdfUploader';
import { PDFReader } from 'reactjs-pdf-reader';
const NewsPDF = (props) => {
    const [section, setSection] = useState('');
    const [title, setTitle] = useState('');
    const [pdf, setPdf] = useState('');
    const [credits, setCredits] = useState('');
    const [alt, setAlt] = useState('');
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const previewCompRef = useRef();
    const db = getFirestore();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            previewBtn: {
                backgroundColor: '#fff',
                color: '#ff6633',
                boxShadow: '0px 3px 6px #00000026',
                borderRadius: '30px',
                ':hover': {
                    backgroundColor: '#ff6633',
                    color: '#FFF',
                },
            },
            saveBtn: {
                backgroundColor: '#ff6633',
                color: '#fff',
                boxShadow: '0px 3px 6px #00000026',
                borderRadius: '30px',
                ':hover': {
                    backgroundColor: '#fff',
                    color: '#ff6633',
                },
            },
            content: {
                background: '#EFF0F7',
                // border: '1px solid #e6e6e6',
                borderRadius: '25px',
                padding: '25px 15px',
                // boxShadow: '0px 3px 6px #ebebeb'
            },
            fieldStyle: {
                '& .MuiInputBase-root': {
                    fontSize: '0.7rem',
                },
                '& .MuiInputLabel-root': {
                    fontSize: '0.7rem',
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                },
                "& fieldset": {
                    border: 'none',
                },
            },

        })
    );

    const { classes } = useStyles();

    const getDateId = (_company) => {
        return `${_company}_${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`;
    };

    const saveNewsPDF = async () => {
        const docTitle = getDateId(company);
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: data.data().News,
                Stocks: data.data().Stocks,
                Weather: data.data().Weather,
                Image: data.data().Image,
                PDF: data.data().PDF.concat({
                    id: generateKey(),
                    section: section,
                    title: title,
                    pdf: pdf,
                    credits: credits,
                    alt: alt,
                    category: 'بي دي أف',
                    date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                        }-${new Date().getFullYear()}`
                }),
                Currency: data.data().Currency,
                data: data.data().data
            }).then((data) => {
                console.log('done update');
            });
        } else {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: [],
                Stocks: '',
                Weather: '',
                Image: '',
                PDF: [{
                    id: generateKey(),
                    section: section,
                    title: title,
                    pdf: pdf,
                    credits: credits,
                    alt: alt,
                    category: 'بي دي أف',
                    date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                        }-${new Date().getFullYear()}`
                }],
                Currency: '',
                data: {
                    date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                        }-${new Date().getFullYear()}`,
                    company: company
                }
            }).then((data) => {
                console.log('done');
            });
        }
        setSnackBar({
            isOpen: true,
            message: 'تم الحفظ في مكتبة الفئات',
        });
    }
    const PreviewFn = () => {
        setItems([{
            section: section,
            title: title,
            pdf: pdf,
            credits: credits,
            alt: alt,
            category: 'بي دي أف',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }

    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);

    return (

        <>
            <Grid container sx={{ justifyContent: 'center', }} lg={12}>

                <Grid container mt={1} sx={{ justifyContent: 'space-between', }} lg={10}>
                    <Grid item xs={6} lg={5.8} className={classes.content}>
                        <Stack spacing={3} direction="column">
                            {/* <Typography variant="h5">بي دي أف</Typography> */}
                            <Stack direction="column" spacing={1}>
                                <TextField
                                    fullWidth
                                    value={section}
                                    onChange={({ target: { value } }) => setSection(value)}
                                    variant="outlined"
                                    //label="الفقرة"
                                    placeholder="الفقرة"
                                    classeName={classes.fieldStyle}
                                />
                                <TextField
                                    fullWidth
                                    value={title}
                                    onChange={({ target: { value } }) => setTitle(value)}
                                    variant="outlined"
                                    label="العنوان"
                                    placeholder="العنوان"
                                />
                            </Stack>
                            <PdfUploader image={pdf} onChange={setPdf} />
                            <PDFReader url="https://firebasestorage.googleapis.com/v0/b/mujaz-platform.appspot.com/o/pdf%2Fd165389a-8c99-4e4f-b7d5-4de57fdca33f.pdf?alt=media&token=e2924080-75b1-47f2-8abd-d9e0d492b8d4" />
                            <Stack direction="row" spacing={1}>
                                <TextField
                                    fullWidth
                                    value={credits}
                                    onChange={({ target: { value } }) => setCredits(value)}
                                    variant="outlined"
                                    label="المصدر"
                                    placeholder="المصدر"
                                />
                                <TextField
                                    fullWidth
                                    value={alt}
                                    onChange={({ target: { value } }) => setAlt(value)}
                                    variant="outlined"
                                    label="وصف الصورة"
                                    placeholder="وصف الصورة"
                                />
                            </Stack>
                            <Grid container mt={4} columns={{ sm: 1, md: 12 }} justifyContent="space-between" alignItems="center">
                                <Grid item xs={6} md={4} >
                                    <Button size='small' variant="outlined" style={{ color: '#FF6150', borderRadius: '15px', padding: '10px' }} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                                </Grid>
                                <Grid item xs={6} md={6} sx={{ display: 'grid' }}>
                                    <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '15px', padding: '10px', }} endIcon={<SaveIcon />} onClick={saveNewsPDF}>حفظ في مكتبة الفئات</Button>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} lg={5.8} style={{ paddingTop: 0 }}>
                        <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    console.log(state);
    return state
}

export default connect(mapStateToProps)(NewsPDF);