import { Card, CardContent, Typography, Paper } from '@mui/material';
import React from 'react';
import RichTextEditor from '../../atoms/RichTextEditor';
import { Grid } from '@mui/material';

const IntroCard = ({ logo, onChange, defaultValue }) => {
	return (
		<Paper elevation={0}>
			{/* <Card variant="outlined">
				<CardContent> */}

			<Grid container direction="column" columns={{lg: 12}} sx={{ justifyContent: "center", alignItems: "center", display: 'flex', padding: '30px 0px' }}>
				{/* <Grid item xs={12}>
						<Grid container justifyContent="space-between">
							<Grid item>
								<Typography variant="caption">
									{Intl.DateTimeFormat('ar').format(new Date())}
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="caption">www.moujaz.com</Typography>
							</Grid>
						</Grid>
					</Grid> */}
				<Grid item lg={12}>
					<img
						src={logo}
						style={{ width: '25%', display: 'block', margin: '0 auto'}}
						alt="logo"
					/>

				</Grid>
			</Grid>
			<Grid item style={{margin: '15px'}}>
				<RichTextEditor defaultValue={defaultValue} onChange={onChange} />
			</Grid>

			{/* </CardContent>
			</Card> */}
		</Paper>
	);
};

export default IntroCard;
