import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import React, { useState, useEffect } from "react";
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Router from './router';
import { doc, addDoc, getDoc, updateDoc, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';


import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase v9+
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { useFirebaseApp, AppCheckProvider, AuthProvider } from 'reactfire';
import { connect } from 'react-redux';



// const APP_CHECK_TOKEN = '6LfCTZghAAAAABi2E5qzej1PeohGXr9Wkhrh-sm7'; //production token


const App = (props) => {
	const { loggedInUser } = props;
	let APP_CHECK_TOKEN = '';
	const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
	const auth = getAuth(app);
	if (process.env.NODE_ENV == 'development') {
		APP_CHECK_TOKEN = process.env.REACT_APP_CHECK_TOKEN_DEBUG; // new dev debug token
		window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
	} else {
		APP_CHECK_TOKEN = process.env.REACT_APP_CHECK_TOKEN; // new dev debug token
	}

	const appCheck = initializeAppCheck(app, {
		provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
		isTokenAutoRefreshEnabled: true,
	});
	const theme = createTheme({
		palette: {
			primary: {
				// main: '#ff6633', //'#F79325',
				main: '#ff7764',
				contrastText: '#fcfcfc',
			},
			secondary: {
				main: '#fcfcfc',
				contrastText: '#ff7764',
			}
		},
		direction: 'rtl',
		typography: {
			fontFamily: ['Tajawal, sans-serif'].join(','),
		},
		shape: {
			borderRadius: 10,
		},
	});
	const db = getFirestore();
	// Create rtl cache
	const cacheRtl = createCache({
		key: 'muirtl',
		stylisPlugins: [rtlPlugin],
	});

	useEffect(async () => {
		if (loggedInUser?.uid !== '') {
			const userRef = collection(db, "Users");
			const q = query(userRef, where("uid", "==", loggedInUser?.uid));
			const querySnapshot = await getDocs(q);
			if (!querySnapshot.empty) {
				props.setUser(querySnapshot.docs[0].data());
			}
		}
	}, [])

	return (
		<AppCheckProvider sdk={appCheck}>
			<AuthProvider sdk={auth}>
				<CacheProvider value={cacheRtl}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<Router />
					</ThemeProvider>
				</CacheProvider>
			</AuthProvider>
		</AppCheckProvider>
	);
};
const mapStateToProps = (state) => {
	return state
}
const dispatchUser = (dispatch) => {
	return {
		setUser: (updatedUser) => { dispatch({ type: 'UPDATE_USER', value: updatedUser }) }
	}
}
export default connect(mapStateToProps, dispatchUser)(App);
// export default App;
