import React, { useEffect, useCallback, useState } from 'react';
import {
    Button,
    Grid,
    Paper,
    IconButton,
    Typography,
    Snackbar,
} from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from "tss-react/mui";
import { Delete, Edit } from '@material-ui/icons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateIntroCard, generateKey, generateWrappedEmail, swapArrayItems } from '../../utils';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, limit, startAfter, getDocs, setDoc, collection, query, where, serverTimestamp } from 'firebase/firestore';
import AddEditSubscriber from '../../components/AddEditSubscriber';
import AddEditSubscriptionList from '../../components/AddEditSubscriptionList';
import { confirm } from "react-confirm-box";
import { connect } from 'react-redux';
import csv from 'csv';
import { ExportToCsv } from 'export-to-csv';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import trash from '../../img/icons/trash.svg'


const Subscribers = (props) => {
    const { company, data } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [newSubVisible, setNewSubVisible] = useState(false);
    const [SubVisible, setSubVisible] = useState([]);
    const [ConfirmationDialogStatus, setConfirmationDialogStatus] = useState(false);
    const [subscriptionListView, setSubscriptionListView] = useState(false);
    const [subscriptionView, setSubscriptionView] = useState(false);
    const [subscriptionListTemp, setSubscriptionListTemp] = useState({
        id: '',
        index: '',
        name: '',
        company: company
    });
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const [lastDocument, setLastDocument] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit] = useState(10);
    const [forwarddDisabled, setForwarddDisabled] = useState(false);
    const [fetchFinish, setFetchFinish] = useState({ maxPage: 0, isFinish: false });

    const db = getFirestore();

    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633',
                // padding: '20px'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px 0px 30px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '2px 2px 10px #ccc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }

            },
            menuBtns: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '50px 0px 0px 0px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '0px 3px 6px #00000026',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },
            tableHeader: {
                // boxShadow: '1px 2px 5px #ddd',
                borderRadius: '10px',
                marginBottom: '20px',
                marginTop: '25px',
                padding: '15px',
                // background: '#eee',
            },
            pagination: {
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
                padding: 20,
                '& svg': {
                    marginRight: 20,
                    cursor: 'pointer',
                },
                '& svg:first-of-type': {
                    marginRight: 0
                }
            },
            backward: {
                fill: currentPage === 1 ? '	#A9A9A9' : '#000'
            },
            forward: {
                fill: forwarddDisabled ? '	#A9A9A9' : '#000'
            },

            confirmBox: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            submitBtn: {
                background: '#f27021 !important',
                color: '#FFF !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            cancelBtn: {
                background: '#FFF !important',
                color: '#f27021 !important',
                border: '1px solid #f27021 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            btns: {
                color: '#f27021'
            }

        })
    );
    const fileInput = React.useRef();
    const { classes } = useStyles();

    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.confirmBox}>
                    <Grid item xs={1} sm={1} md={12}>
                        <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Typography variant="h5">{message}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={onCancel}> تراجع </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button variant="contained" className={classes.submitBtn} size="small" onClick={onConfirm}> تأكيد </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            );
        }
    };



    const getDetails = async () => {
        const subRef = collection(db, "Subscribers");
        const q = query(subRef, where("company", "==", company));
        let data = await getDocs(q);
        data.forEach((doc) => {
            console.log(doc.data());
            setItems(items => [...items, {
                id: doc.id,
                company: doc.data().company,
                name: doc.data().name,
                email: doc.data().email
            }]);
            setSubVisible(prev => [...prev, false]);
        });




        setIsLoading(false);
    };

    const getSubscribersFromList = async (index, row) => {
        let q = query(collection(db, 'Subscribers'), where("company", "==", company), where("subscriptionList", "array-contains", row.id));
        const querySnapshot = await getDocs(q);
        // const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        // setLastDocument(lastVisible);
        let tempUsers = [];
        querySnapshot.forEach((entities) => {
            tempUsers.push({ key: entities.id, ...entities.data() });
        });
        setItems(tempUsers);
    }
    const getAllSubscribers = async (db, company) => {
        let subscribers = [];
        let lastVisible = null;
        let hasMore = true;
        const batchSize = 10;  // Fetch 10 documents per batch
    
        // Loop to fetch data in batches
        while (hasMore) {
            let q;
            if (lastVisible) {
                // If lastVisible exists, use startAfter to continue after the last document in the previous batch
                q = query(
                    collection(db, 'Subscribers'),
                    where("company", "==", company),
                    startAfter(lastVisible),
                    limit(batchSize)
                );
            } else {
                // Initial query (no startAfter)
                q = query(
                    collection(db, 'Subscribers'),
                    where("company", "==", company),
                    limit(batchSize)
                );
            }
            const querySnapshot = await getDocs(q);
            // Add each document to the subscribers array
            querySnapshot.forEach(doc => {
                subscribers.push({ id: doc.id, ...doc.data() });
            });
    
            // Update lastVisible to the last document in the current batch
            lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    
            // Check if there are more documents to fetch
            hasMore = querySnapshot.size === batchSize;
        }
    
        return subscribers;
    }

    const init = useCallback(async () => {
        let tempUsers = [];
        let q = query(collection(db, 'Subscribers'), where("company", "==", company), limit(10));
        const querySnapshot = await getDocs(q);

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastDocument(lastVisible);
        querySnapshot.forEach((entities) => {
            // doc.data() is never undefined for query doc snapshots
            tempUsers.push({ key: entities.id, ...entities.data() });
        });
        setItems(tempUsers);

        // Fetch all subscription lists
        const subscriptionListSnapshot = await getDocs(collection(db, 'SubscriptionList'));
        const subscriptionListsData = [];
        subscriptionListSnapshot.forEach(doc => {
            subscriptionListsData.push({ id: doc.id, ...doc.data() });
        });
        setSubscriptionList(subscriptionListsData);
    }, [db]);

    const handlePaginate = async (index) => {
        let tempUsers = [];
        let querySnapshot;
        let page = currentPage;
        let nextQuery;

        if (index) {
            if (!fetchFinish.isFinish) {
                nextQuery = query(collection(db, 'Subscribers'), where("company", "==", company), startAfter(lastDocument), limit(10));
                querySnapshot = await getDocs(nextQuery);
                ++page;

                if (querySnapshot.docs.length) {
                    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    setLastDocument(lastVisible);

                    querySnapshot.forEach((entities) => {
                        tempUsers.push({ key: entities.id, ...entities.data() });
                    });
                    setCurrentPage(page);
                    setItems([...items, ...tempUsers]);
                    forwarddDisabled && setForwarddDisabled(false);
                } else {
                    setFetchFinish({ maxPage: page, isFinish: true });
                    setForwarddDisabled(true);
                }
            } else {
                page !== fetchFinish.maxPage && ++page;
                setCurrentPage(page);
                page === fetchFinish.maxPage - 1 && !forwarddDisabled && setForwarddDisabled(true);
            }

        } else {
            --page;
            setCurrentPage(page);
            forwarddDisabled && setForwarddDisabled(false);
        }

    }

    const handleSubVisible = (index) => {
        let arr = [...SubVisible];
        arr[index] = !arr[index];
        setSubVisible(arr);
    };

    const handleNewSubscriber = () => {
        setSubscriptionView(true);
    }

    const handleAddSubscriber = async (name, email, subList, id, index) => {
        if (id === '') {
            const docRef = await addDoc(collection(db, "Subscribers"), {
                name: name,
                email: email,
                subscriptionList: subList,
                company: company,
                createdAt: serverTimestamp()
            }).then((data) => {
                setItems(items => [...items, {
                    id: data.id,
                    company: company,
                    name: name,
                    email: email
                }]);
                setSubscriptionView(false);
                setSnackBar({
                    isOpen: true,
                    message: 'تم إضافة المشترك بنجاح',
                });
            });
        } else {
            await setDoc(doc(db, 'Subscribers', id), {
                name: name,
                email: email,
                subscriptionList: subList,
                company: company
            }).then(() => {
                let newItemObj = items.map(obj => {
                    if (obj.id == id) {
                        return {
                            id: id,
                            name: name,
                            email: email,
                            company: company
                        }
                    }
                    return obj;
                });
                setItems(newItemObj);
                setSubscriptionView(false);
                setSnackBar({
                    isOpen: true,
                    message: 'تم تعديل المشترك بنجاح',
                });
            });
        }

    }

    const handleDeleteSubscriber = async (i) => {
        const result = await confirm("هل حقا تريد حذف هذا المشترك؟", options);
        if (result) {
            await deleteDoc(doc(db, "Subscribers", i.key)).then(() => {
                console.log('deleted: ' + i.key);
                setItems(items.filter(item => item.key !== i.key));
                setSnackBar({
                    isOpen: true,
                    message: 'تم مسح المشترك بنجاح',
                });
            });
        }
    }
    const handleDeleteSubscriberList = async (i) => {
        const result = await confirm("هل حقا تريد حذف هذه القائمة؟", options);
        if (result) {
            let q = query(collection(db, 'Subscribers'), where("company", "==", company), where("subscriptionList", "array-contains", i.id));
            const querySnapshot = await getDocs(q);
            const numberOfRecords = querySnapshot.size;
            if (numberOfRecords < 1) {
                await deleteDoc(doc(db, "SubscriptionList", i.id)).then(() => {
                    console.log('deleted: ' + i.id);
                    setSubscriptionList(subscriptionList.filter(item => item.id !== i.id));
                    setSnackBar({
                        isOpen: true,
                        message: 'تم مسح القائمة بنجاح',
                    });
                });
            } else {
                setSnackBar({
                    isOpen: true,
                    message: 'لا يمكن مسح القائمة لإحتوائها على مشتركين',
                });
            }

        }
    }

    const handleNewSubscriptionList = () => {
        setSubscriptionListTemp({ id: '', index: '', name: '', company: company });
        setSubscriptionListView(true);
    }
    const handleEditSubscriptionList = (id, index, name) => {
        setSubscriptionListTemp({ id: id, index: index, name: name, company: company });
        setSubscriptionListView(true);
    }
    const handleAddSubscriptionList = async (name, id, index) => {
        if (id === '') {
            console.log(name);
            const docRef = await addDoc(collection(db, "SubscriptionList"), {
                name: name,
                company: company,
                createdAt: serverTimestamp()
            }).then((data) => {
                setSubscriptionList(items => [...items, {
                    id: data.id,
                    company: company,
                    name: name,
                }]);
                setSubscriptionListView(false);
                setSnackBar({
                    isOpen: true,
                    message: 'تم إضافة القائمة بنجاح',
                });
            });
        } else {
            await setDoc(doc(db, 'SubscriptionList', id), {
                name: name,
                company: company
            }).then(() => {
                let subscriptionListObj = subscriptionList.map(obj => {
                    if (obj.id == id) {
                        return {
                            id: id,
                            name: name,
                            company: company
                        }
                    }
                    return obj;
                });
                setSubscriptionList(subscriptionListObj);
                setSubscriptionListView(false);
                setSnackBar({
                    isOpen: true,
                    message: 'تم تعديل القائمة بنجاح',
                });
            });
        }
    }

    useEffect(() => {
        init();
    }, [init]);

    const uploadSubscribers = async () => {
        if (document.getElementById('csv-file').files.length > 0) {
            if (document.getElementById('csv-file').files[0].type == 'text/csv') {
                let file = document.getElementById('csv-file').files[0];
                const result = await confirm("هل حقا تريد إدخال مشتركين جدد؟", options);
                if (result) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        csv.parse(reader.result, (err, data) => {
                            for (let i = 1; i < data.length; i++) {
                                const name = data[i][0];
                                const email = data[i][1];
                                const newUser = { "email": email, "name": name, "company": company };
                                const docRef = addDoc(collection(db, "Subscribers"), newUser).then((data) => {
                                    console.log("Document written with ID: ", data.id);
                                    setItems(items => [...items, {
                                        id: data.id,
                                        company: company,
                                        name: name,
                                        email: email
                                    }]);
                                });
                            };
                            console.log('subscribers imported successfully');
                            setSnackBar({
                                isOpen: true,
                                message: 'تم إستيراد المشتركين بنجاح',
                            });
                        });

                    };

                    reader.readAsBinaryString(file);
                }
            } else {
                console.log('invalid format');
            }
        }
    }
    const exportSubscribers = async () => {
        const result = await confirm("هل حقا تريد تحميل المشتركين؟", options);
        if (result) {
            const csvOptions = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'My Awesome CSV',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: company,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };
            try {
                // Await the result of getAllSubscribers to ensure it's resolved before generating CSV
                const allSubscribers = await getAllSubscribers(db, company);
                const csvExporter = new ExportToCsv(csvOptions);
                csvExporter.generateCsv(allSubscribers);
    
                setSnackBar({
                    isOpen: true,
                    message: 'تم تحميل المشتركين بنجاح',
                });
            } catch (error) {
                console.error("Error exporting subscribers:", error);
                setSnackBar({
                    isOpen: true,
                    message: 'حدث خطأ أثناء تحميل المشتركين',
                });
            }
        }
    }
    return (
        <>
            <Grid container spacing={2} mt={4} columns={{ md: 12 }} justifyContent="center">
                <Grid item md={6}>
                    <Paper elevation={0} >
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Grid container sx={{ width: '100%', padding: '20px 40px' }} justifyContent="center" alignItems="center">
                                <input
                                    ref={fileInput}
                                    type="file"
                                    id="csv-file"
                                    onChange={() => uploadSubscribers()}
                                    style={{ display: 'none' }}
                                />
                                <Grid item >
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}> موجز الاقتصاد</Typography>
                                    {/* <Typography variant="body2" sx={{ fontSize: '12px' }}>  قم باضافة او تعديل او حذف قائمة المشتركين</Typography> */}
                                </Grid>
                                <Grid container mb={2} mt={5} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Grid item>
                                        <Typography variant="body2" sx={{ fontWeight: 500, fontSize: '12px', color: '#FF6150', cursor: 'pointer' }} >
                                            *تحميل نموذج قائمة المشتركين
                                        </Typography>
                                    </Grid>

                                    <Grid item >
                                        <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '7px', marginLeft: '10px' }} onClick={() => exportSubscribers()} >
                                            تحميل القائمة
                                        </Button>
                                        <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '7px', marginLeft: '10px' }} onClick={() => fileInput.current.click()} >
                                            إستيراد مشتركين
                                        </Button>
                                        <Button size='small' style={{ backgroundColor: '#FF6150', borderRadius: '7px' }} variant="contained" onClick={() => handleNewSubscriber()}>
                                            اضافة مشترك
                                        </Button>

                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} style={{ boxShadow: 'none', }}>
                                    <Table sx={{ minWidth: 500, }} aria-label="simple table">
                                        <TableHead sx={{
                                            background: '#F5F6FA',
                                            borderRadius: '10px',
                                            overflow: 'hidden',

                                        }}>
                                            <TableRow sx={{
                                                background: '#F5F6FA',
                                                '&:first-of-type': {
                                                    borderTopLeftRadius: '10px',
                                                    borderTopRightRadius: '10px',
                                                    borderBottomRightRadius: '10px',
                                                    borderBottomLeftRadius: '10px',
                                                },
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}>
                                                <TableCell sx={{ borderBottom: "none", flex: 1, textAlign: "start" }} align="center">
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        الإسم
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        البريد الالكتروني
                                                    </Typography>
                                                </TableCell>

                                                {/*<TableCell sx={{ borderBottom: 'none', flex: 1, }} align="center">
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        النشرات المرسله
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: 'none', flex: 1, }} align="center">
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        المشتركين
                                                    </Typography>
                                                </TableCell> */}
                                                <TableCell sx={{ borderBottom: "none", borderBottomLeftRadius: '10px', flex: 1, textAlign: "end" }} align="center">
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        الاجراءات
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {items.map((row, index) => {
                                                if (index < currentPage * currentLimit && (currentPage === 1 ? true : index >= currentPage * currentLimit - 10)) {
                                                    return (
                                                        // <>
                                                        <TableRow
                                                            key={index}
                                                            index={index}
                                                            sx={{
                                                                '&:last-child td, &:last-child th': { border: 0 },
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, textAlign: "start" }} align="center">
                                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                    {row.name}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, textAlign: "start" }} align="center">
                                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                    {row.email}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '0px', borderBottom: 'none', flex: 1, textAlign: "end" }} align="center">
                                                                <IconButton aria-label="edit" className={classes.btns} onClick={() => handleSubVisible(index, row)} >
                                                                    <Edit style={{ fontSize: '1rem' }} />
                                                                </IconButton>
                                                                <IconButton aria-label="delete" className={classes.btns} onClick={() => handleDeleteSubscriber(row)} >
                                                                    <img src={trash} alt="Trash Logo" style={{ width: '18px', height: '18px', }} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>

                                                        // </>
                                                    )
                                                }
                                            }


                                            )}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Box>

                    </Paper>
                    <div className={classes.pagination}>
                        <IconButton disabled={currentPage === 1} onClick={() => handlePaginate(0)}>
                            <ArrowForwardIosIcon className={classes.backward} />
                        </IconButton>
                        <IconButton disabled={forwarddDisabled} onClick={() => handlePaginate(1)}>
                            <ArrowBackIosIcon className={classes.forward} />
                        </IconButton>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <Paper elevation={0} >
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Grid container sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', padding: '20px 40px' }} >

                                <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center', }} >
                                    <Grid item >
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}> قوائم المشتركين</Typography>
                                        {/* <Typography variant="body2" sx={{ fontSize: '12px' }}>  قم باضافة او تعديل او حذف قائمة المشتركين</Typography> */}
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '12px', color: '#FF6150', cursor: 'pointer' }} onClick={() => handleNewSubscriptionList()}>
                                            إضافة قائمة جديدة
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <TableContainer style={{ boxShadow: 'none' }}>
                                    <Table aria-label="simple table" style={{ marginRight: '-10px', marginTop: '60px' }}>
                                        <TableBody>
                                            {subscriptionList.map((row, index) => {
                                                // console.log(subscriptionList);
                                                // if (index < currentPage * currentLimit && (currentPage === 1 ? true : index >= currentPage * currentLimit - 10)) {
                                                return (
                                                    // <>

                                                    <TableRow
                                                        key={index}
                                                        index={index}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 },
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, textAlign: "start" }} align="center">
                                                            <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                {row.name}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ padding: '0px', borderBottom: 'none', flex: 1, textAlign: "end" }} align="center">
                                                            <IconButton aria-label="preview" className={classes.btns} onClick={() => { getSubscribersFromList(index, row) }}>
                                                                <VisibilityIcon style={{ fontSize: '1rem' }} />
                                                            </IconButton>
                                                            <IconButton aria-label="edit" className={classes.btns} onClick={() => handleEditSubscriptionList(row.id, index, row.name)}>
                                                                <Edit style={{ fontSize: '1rem' }} />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" className={classes.btns} onClick={() => handleDeleteSubscriberList(row)}>
                                                                <img src={trash} alt="Trash Logo" style={{ width: '18px', height: '18px', }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                    // </>
                                                )
                                                // }
                                            }
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            {
                subscriptionListView && <AddEditSubscriptionList
                    name={subscriptionListTemp.name}
                    id={subscriptionListTemp.id}
                    index={subscriptionListTemp.index}
                    handleAddSubscriptionList={handleAddSubscriptionList}
                    buttonName="حفظ"
                    onCloseIt={() => {
                        setTimeout(() => { setSubscriptionListView(false); }, 500);
                    }}
                />
            }
            {
                subscriptionView && <AddEditSubscriber
                    name=""
                    list={subscriptionList}
                    id=""
                    buttonName="إضافة"
                    handleAddSubscriber={handleAddSubscriber}
                    onCloseIt={() => {
                        setTimeout(() => { setSubscriptionView(false); }, 500);
                    }} />
            }
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(Subscribers);
