import React, { useEffect, useCallback, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    IconButton,
    TextField,
    Typography,
    Snackbar,
    sx,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { Delete, Edit } from '@material-ui/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateIntroCard, generateKey, generateWrappedEmail, swapArrayItems } from '../../utils';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where, limit, startAfter } from 'firebase/firestore';
import { getAuth, signOut, createUserWithEmailAndPassword, deleteUser, sendEmailVerification } from "firebase/auth";


import AddEditUser from '../../components/AddEditUser';
import { confirm } from "react-confirm-box";
import { connect } from 'react-redux';
import firebase, { initializeApp } from 'firebase/app';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { create } from 'lodash';
import trash from '../../img/icons/trash.svg'

const Users = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [newUserVisible, setNewUserVisible] = useState(false);
    const [listUserVisible, setListUserVisible] = useState(true);
    const [UserVisible, setUserVisible] = useState(false);

    const [lastDocument, setLastDocument] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit] = useState(5);
    const [forwarddDisabled, setForwarddDisabled] = useState(false);
    const [fetchFinish, setFetchFinish] = useState({ maxPage: 0, isFinish: false });

    const [editUser, setEditUser] = useState({
        id: '',
        uid: '',
        name: '',
        responsibility: '',
        company: '',
        email: '',
        index: 0
    })
    const [ConfirmationDialogStatus, setConfirmationDialogStatus] = useState(false);
    const db = getFirestore();
    const { company, data } = props;
    const auth = getAuth();

    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633',
                // padding: '20px'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px 0px 30px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '2px 2px 10px #ccc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }

            },
            addUserBtn: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '25px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '5px 5px 5px #ccc',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },
            tableHeader: {
                // boxShadow: '1px 2px 5px #ddd',
                borderRadius: '10px',
                marginBottom: '20px',
                marginTop: '25px',
                // padding: '15px',
                // background: '#eee',
            },
            pagination: {
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
                padding: 20,
                '& svg': {
                    marginRight: 20,
                    cursor: 'pointer',
                },
                '& svg:first-of-type': {
                    marginRight: 0
                }
            },
            backward: {
                fill: currentPage === 1 ? '	#A9A9A9' : '#000'
            },
            forward: {
                fill: forwarddDisabled ? '	#A9A9A9' : '#000'
            },

            confirmBox: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            submitBtn: {
                background: '#f27021 !important',
                color: '#FFF !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            cancelBtn: {
                background: '#FFF !important',
                color: '#f27021 !important',
                border: '1px solid #f27021 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            btns: {
                color: '#f27021',
            },
            headerCellRight: {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                overflow: 'hidden'
            },
            headerCellLeft: {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                borderBottom: "none"
            },

        })
    );

    const firebaseConfigSecondary = {
        apiKey: "AIzaSyCPRnE1krVgrLxiQwYpya_GN4VMG0F3mrs",
        authDomain: "mujaz-platform.firebaseapp.com",
        projectId: "mujaz-platform",
        storageBucket: "mujaz-platform.appspot.com",
        messagingSenderId: "988604723058",
        appId: "1:988604723058:web:7cd884ca6736903381f7eb",
        measurementId: "G-H9L2H43NBN"
    };

    // Initialize 2nd Firebase app
    const secondaryApp = initializeApp(firebaseConfigSecondary, "secondary");
    const secondaryAuth = getAuth(secondaryApp);
    const { classes } = useStyles({ currentPage, forwarddDisabled });

    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.confirmBox}>
                    <Grid item xs={1} sm={1} md={12}>
                        <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Typography variant="h5">{message}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={onCancel}> تراجع </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button variant="contained" className={classes.submitBtn} size="small" onClick={onConfirm}> تأكيد </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            );
        }
    };
    const getDetails = async () => {
        const subRef = collection(db, "Users");
        const q = query(subRef, where("company", "==", company));
        let data = await getDocs(q);
        data.forEach((doc) => {
            console.log(doc.data());
            setItems(items => [...items, {
                id: doc.id,
                company: doc.data().company,
                name: doc.data().name,
                logo: doc.data().logo,
                email: doc.data().email,
                responsibility: doc.data().responsibility,
                uid: doc.data().uid
            }]);
            // setUserVisible(prev => [...prev, false]);
        });
        setIsLoading(false);
    };

    const init = useCallback(async () => {
        let tempUsers = [];
        let q = query(collection(db, 'Users'), where("company", "==", company)); //,limit(5)
        const querySnapshot = await getDocs(q);

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastDocument(lastVisible);
        querySnapshot.forEach((entities) => {
            // doc.data() is never undefined for query doc snapshots
            if (entities.data().email != data.personalProfile.personalData.username) {
                tempUsers.push({ key: entities.id, ...entities.data() });
            }
        });
        setItems(tempUsers);
    }, [db]);
    const handlePaginate = async (index) => {
        let tempUsers = [];
        let querySnapshot;
        let page = currentPage;
        let nextQuery;
        if (index) {

            if (!fetchFinish.isFinish) {
                nextQuery = query(collection(db, 'Users'), where("company", "==", company), startAfter(lastDocument), limit(5));
                querySnapshot = await getDocs(nextQuery);
                ++page;

                if (querySnapshot.docs.length) {
                    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    setLastDocument(lastVisible);

                    querySnapshot.forEach((entities) => {
                        tempUsers.push({ key: entities.id, ...entities.data() });
                    });
                    setCurrentPage(page);
                    setItems([...items, ...tempUsers]);
                    forwarddDisabled && setForwarddDisabled(false);
                } else {
                    setFetchFinish({ maxPage: page, isFinish: true });
                    setForwarddDisabled(true);
                }
            } else {
                page !== fetchFinish.maxPage && ++page;
                setCurrentPage(page);
                page === fetchFinish.maxPage - 1 && !forwarddDisabled && setForwarddDisabled(true);
            }

        } else {
            --page;
            setCurrentPage(page);
            forwarddDisabled && setForwarddDisabled(false);
        }

    }

    const handleUserVisible = (index, i) => {
        setEditUser({
            id: i.key,
            name: i.name,
            responsibility: i.responsibility,
            logo: i.logo,
            company: i.company,
            email: i.email,
            uid: i.uid,
            index: index
        })
        setUserVisible(prev => !prev);
        setListUserVisible(prev => !prev);

    };
    const handleCancelAddUser = () => {
        setUserVisible(false);
        setNewUserVisible(false);
        setListUserVisible(true);
    }
    const handleAddUser = async (name, responsibility, email, password, logo, id, uid, index) => {
        if (id === '') {
            // deleteUser()
            createUserWithEmailAndPassword(secondaryAuth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    sendEmailVerification(userCredential.user)
                        .then(() => {
                            signOut(secondaryAuth); //signout the new user
                            const docRef = addDoc(collection(db, "Users"), {
                                _2fa: {
                                    enabled: false,
                                    secret: ''
                                },
                                name: name,
                                email: email,
                                company: company,
                                responsibility: responsibility,
                                logo: logo,
                                uid: user.uid
                            }).then((data) => {
                                setItems(items => [...items, {
                                    id: data.id,
                                    _2fa: {
                                        enabled: false,
                                        secret: ''
                                    },
                                    company: company,
                                    name: name,
                                    email: email,
                                    responsibility: responsibility,
                                    logo: logo,
                                    uid: user.uid
                                }]);
                                setNewUserVisible(prev => !prev);
                                setListUserVisible(prev => !prev);
                            });
                        })
                        .catch((err) => {

                        })
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                });
        } else {
            await setDoc(doc(db, 'Users', id), {
                name: name,
                email: email,
                company: company,
                responsibility: responsibility,
                logo: logo
            }).then(() => {
                let newItemObj = items.map(obj => {
                    console.log(obj);
                    console.log(id);

                    if (obj.key == id) {
                        return {
                            key: id,
                            name: name,
                            email: email,
                            company: company,
                            responsibility: responsibility,
                            logo: logo
                        }
                    }
                    return obj;
                });
                setItems(newItemObj);
                setUserVisible(prev => !prev);
                setListUserVisible(prev => !prev);
            });
        }
    }

    const handleDeleteUser = async (user) => {
        const result = await confirm("هل حقا تريد حذف هذا المستخدم؟", options);
        if (result) {
            console.log(user);
            console.log(auth.auth())

            // auth.currentUser(user).then(() => { console.log('qwe') })
            // .then(() => {
            //     deleteDoc(doc(db, "Users", id)).then(() => {
            //         setItems(items.filter(item => item.key !== id));
            //     });
            // });

        }
    }

    useEffect(() => {
        init();
    }, [init]);



    return (
        <>
            <Grid container columns={{ sm: 12, md: 12, lg: 12 }} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid item md={12} lg={12}>
                    <Grid container spacing={2} style={{ flexDirection: 'row' }}>
                        <Grid item>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}> إدارة المستخدمين</Typography>
                            <Typography variant="body2" sx={{ fontSize: '12px' }}> معلومات وصلاحيات المستخدمين</Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{ flexDirection: 'row-reverse', }}>
                        <Grid item>
                            <Button size='small' style={{ backgroundColor: '#FF6150', borderRadius: '7px' }} variant="contained" onClick={() => {
                                setNewUserVisible(true);
                                setListUserVisible(false);
                                setUserVisible(false);
                            }}>
                                إضافة مستخدم
                            </Button>
                        </Grid>
                    </Grid>

                    {
                        newUserVisible &&
                        <AddEditUser
                            name=""
                            email=""
                            responsibility=""
                            company=""
                            id=""
                            uid=""
                            buttonName="إضافة"
                            handleAddUser={handleAddUser}
                            handleCancelAddUser={handleCancelAddUser}
                            onCloseIt={() => {
                                setTimeout(() => { setNewUserVisible(false); }, 500);
                                // setListUserVisible(true);
                                // setUserVisible(true);
                            }}
                        />
                    }
                    {UserVisible &&
                        <AddEditUser
                            name={editUser.name}
                            email={editUser.email}
                            responsibility={editUser.responsibility}
                            company={editUser.company}//
                            logo={editUser.logo}
                            id={editUser.id}
                            uid={editUser.uid}
                            index={editUser.index}
                            handleAddUser={handleAddUser}
                            handleCancelAddUser={handleCancelAddUser}
                            buttonName="حفظ"
                            onCloseIt={() => {
                                setTimeout(() => { setUserVisible(false); }, 500);
                            }} />
                    }
                    {/* {listUserVisible && */}
                    <TableContainer component={Paper} style={{ boxShadow: 'none', padding: '10px 0px 0px 0px' }}>
                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                            <TableHead sx={{ background: '#F5F6FA', borderRadius: '10px', overflow: 'hidden' }}>
                                <TableRow sx={{
                                    background: '#F5F6FA',
                                    '&:first-of-type': {
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                    }
                                }}>
                                    <TableCell className={classes.headerCellRight} sx={{ borderBottom: "none", }} align="left">
                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                            اسم المستخدم
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }} align="center">
                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                            البريد اللإلكتروني
                                        </Typography>
                                    </TableCell>

                                    <TableCell sx={{ borderBottom: 'none' }} align="center">
                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                            الصلاحيات
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" className={classes.headerCellLeft} sx={{ borderBottom: "none" }} >
                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                            إجراءات
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((row, index) => {
                                    // if (index < currentPage * currentLimit && (currentPage === 1 ? true : index >= currentPage * currentLimit - 10)) {
                                    return (

                                        <TableRow
                                            key={index}
                                            index={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", }} align="left">
                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                    {row.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", }} align="center">
                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                    {row.email}
                                                </Typography>
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", }} align="center">
                                                <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                    {row.responsibility}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ padding: '0px', borderBottom: 'none', }} align="right">
                                                {/* <IconButton aria-label="edit" className={classes.btns} onClick={() => handleUserVisible(index, row)}>
                                                    <Edit style={{ fontSize: '1rem' }} />
                                                </IconButton>
                                                <IconButton aria-label="delete" className={classes.btns} onClick={() => handleDeleteUser(row)}>
                                                    <Delete style={{ fontSize: '1rem' }} />
                                                </IconButton> */}

                                                <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleUserVisible(index, row)}>
                                                    <Edit fontSize='small' />
                                                </IconButton>
                                                <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteUser(row)}>
                                                    <img src={trash} alt="Trash Logo" style={{ width: '20px', height: '20px', }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                    // }
                                }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* } */}
                </Grid>
            </Grid>

        </>
    );
};
const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(Users);
