import { Stack, TextField, Grid, Button, Typography, Snackbar } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import ImageUploader from '../../components/ImageUploader';
import { generateKey } from '../../utils';
import SideBarMenu from '../../components/SideBarMenu';
import { makeStyles } from "tss-react/mui";
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc } from 'firebase/firestore';
import HTMLViewer from '../../components/HTMLViewer';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import Preview from '../../components/Preview';
import { connect } from 'react-redux';
import PdfUploader from '../../components/PdfUploader';

const NewsImage = (props) => {
    const [section, setSection] = useState('');
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [credits, setCredits] = useState('');
    const [alt, setAlt] = useState('');
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const previewCompRef = useRef();
    const db = getFirestore();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            content: {
                background: '#EFF0F7',
                borderRadius: '15px',
                padding: '25px !important',
            }

        })
    );

    const { classes } = useStyles();

    const getDateId = (_company) => {
        return `${_company}_${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`;
    };

    const saveNewsImage = async () => {
        const docTitle = getDateId(company);

        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: data.data().News,
                Stocks: data.data().Stocks,
                Weather: data.data().Weather,
                Image: data.data().Image.concat({
                    id: generateKey(),
                    section: section,
                    title: title,
                    image: image,
                    imageURL: imageURL,
                    credits: credits,
                    alt: alt,
                    category: 'صورة',
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                }),
                Currency: data.data().Currency,
                data: data.data().data
            }).then((data) => {
                console.log('done update');
            });
        } else {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: [],
                Stocks: '',
                Weather: '',
                Image: [{
                    id: generateKey(),
                    section: section,
                    title: title,
                    image: image,
                    imageURL: imageURL,
                    credits: credits,
                    alt: alt,
                    category: 'صورة',
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                }],
                Currency: '',
                data: {
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`,
                    company: company
                }
            }).then((data) => {
                console.log('done');
            });
        }
        setSnackBar({
            isOpen: true,
            message: 'تم الحفظ في مكتبة الفئات',
        });
    }
    const PreviewFn = () => {
        setItems([{
            section: section,
            title: title,
            image: image,
            imageURL: imageURL,
            credits: credits,
            alt: alt,
            category: 'صورة',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }

    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);

    return (

        <>
            <Grid container sx={{ justifyContent: 'center', }} lg={12}>
                <Grid container mt={1} sx={{ justifyContent: 'space-between', }} lg={10}>
                    <Grid item xs={6} lg={5.8} >
                        <Stack spacing={3} className={classes.content} direction="column">
                            {/* <Typography variant="h5">صورة</Typography> */}
                            <Stack direction="column" spacing={3}>
                                <TextField
                                    fullWidth
                                    value={section}
                                    onChange={({ target: { value } }) => setSection(value)}
                                    variant="outlined"
                                    label="الفقرة"
                                    placeholder="الفقرة"
                                />
                                <TextField
                                    fullWidth
                                    value={title}
                                    onChange={({ target: { value } }) => setTitle(value)}
                                    variant="outlined"
                                    label="عنوان الصورة"
                                    placeholder="عنوان الصورة"
                                />
                            </Stack>
                            <ImageUploader textImage={'إضافة صورة'} image={image} onChange={setImage} />
                            <Stack direction="row" spacing={1}>
                                <TextField
                                    fullWidth
                                    value={credits}
                                    onChange={({ target: { value } }) => setCredits(value)}
                                    variant="outlined"
                                    label="المصدر"
                                    placeholder="المصدر"
                                />
                                <TextField
                                    fullWidth
                                    value={alt}
                                    onChange={({ target: { value } }) => setAlt(value)}
                                    variant="outlined"
                                    label="وصف الصورة"
                                    placeholder="وصف الصورة"
                                />
                                <TextField
                                    fullWidth
                                    value={imageURL}
                                    onChange={({ target: { value } }) => setImageURL(value)}
                                    variant="outlined"
                                    label="رابط الصورة"
                                    placeholder="رابط الصورة"
                                />
                            </Stack>
                        </Stack>
                        <Grid container mt={4} columns={{ sm: 12, md: 12, lg: 12 }} sx={{justifyContent:"space-between", alignItems:"center"}}>					
							<Grid item xs={6} md={6} lg={6} >
								<Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '5px', padding: '10px', }} onClick={saveNewsImage}>حفظ في مكتبة المحتوى</Button>
							</Grid>
							<Grid item xs={6} md={3} lg={3} sx={{ textAlign: "right" }}>
								<Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px',width: '60%' }} onClick={PreviewFn}>معاينة</Button>
							</Grid>
						</Grid>

                    </Grid>

                    <Grid item xs={6} lg={5.8} style={{ paddingTop: 0 }} >
                        <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />

                    </Grid>


                </Grid>
                <Grid item xs={1} sm={1} md={6} style={{ paddingTop: 0 }}>
                </Grid>
                {/* <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button size='small' variant="outlined" endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                        <Button size='small' variant="contained" endIcon={<SaveIcon />} onClick={saveNewsImage}>حفظ في مكتبة الفئات</Button>
                    </Grid>
                </Grid> */}
            </Grid>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    console.log(state);
    return state
}

export default connect(mapStateToProps)(NewsImage);
