import { Button, Divider, Stack, Typography, Grid, Paper } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import RichTextEditor from '../../atoms/RichTextEditor';
import { generateKey } from '../../utils';
import StockRow from '../StockRow';
import { makeStyles } from "tss-react/mui";
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Preview from '../../components/Preview';


const ConfirmStocksCard = ({ props, defaultValue, handleCancelConfirmCard, handleEditConfirmStocksCard }) => {
    const [stocks, setStocks] = useState(
        defaultValue
            ? defaultValue.items
            : [
                {
                    key: generateKey(),
                    name: '',
                    value: 0,
                    change: '0',
                },
            ]
    );
    const [body, setBody] = useState(defaultValue && defaultValue.body ? defaultValue.body : '');
    const previewCompRef = useRef();
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const { data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                padding: '20px'

            },
            paper: {
                padding: '20px',
            },
            cancelBtn: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmBtn: {
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            content: {
                background: '#fff',
                borderRadius: '25px',
                padding: '25px',
            }
        })
    );

    const PreviewFn = () => {
        setItems([{
            items: stocks,
            body: body,
            category: 'البورصة',
            title: 'توقعات البورصة',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }

    const { classes } = useStyles();
    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);


    return (
        <>
            <Grid container spacing={4} mt={1} style={{ marginRight: 0, width: '100%' }}>
                <Grid item xs={6} className={classes.content}>
                    <Stack spacing={3} direction="column">
                        <Typography variant="h5">البورصة</Typography>
                        <Stack direction="column" divider={<Divider />} spacing={2}>
                            {stocks.map((stock, index) => (
                                <StockRow
                                    key={stock.key}
                                    id={stock.key}
                                    onChange={(newStock) => {
                                        let temp = [...stocks];
                                        temp.splice(index, 1, newStock);
                                        setStocks(temp);
                                    }}
                                    item={stock}
                                    onDelete={() => {
                                        let temp = [...stocks];
                                        temp.splice(index, 1);
                                        setStocks(temp);
                                    }}
                                />
                            ))}
                        </Stack>
                        <Button
                            fullWidth
                            onClick={() =>
                                setStocks([
                                    ...stocks,
                                    {
                                        key: generateKey(),
                                        name: '',
                                        value: 0,
                                        change: '+0',
                                    },
                                ])
                            }
                        >
                            <AddCircleOutlineIcon />
                        </Button>
                        <RichTextEditor defaultValue={body} onChange={setBody} />
                    </Stack>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
                <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={handleCancelConfirmCard}
                        >
                            رجوع
                        </Button>
                        <Button
                            className={classes.confirmBtn}
                            variant="contained"
                            onClick={e => handleEditConfirmStocksCard(stocks, body, defaultValue.date)}
                        >
                            تأكيد
                        </Button>
                        <Button variant="contained" className={classes.previewBtn} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                        {/* <Button variant="contained" className={classes.saveBtn} endIcon={<SaveIcon />} onClick={saveNewsStocks}>حفظ في مكتبة الفئات</Button> */}
                    </Grid>
                </Grid>
            </Grid>
        </>

    );
};

export default ConfirmStocksCard;
